import strive_logo from './strive.png'
import strive_logo_name from './logo_name.png'
import './main.css'
import './f_style.css'

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux'

import { useCookies } from 'react-cookie';


const BootstrapButton = withStyles({
    root: {
        boxShadow: 2,
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#8F94FF',
        // borderColor: '#0063cc',
        fontFamily: [
            "San Francisco", 
            'Helvetica', 
            'Arial',
            '-apple-system',
        ].join(','),
        '&:hover': {
            backgroundColor: '#9EA2FF',
            borderColor: '#8F94FF',
            boxShadow: 2,
        },
        // '&:active': {
        //     boxShadow: 'none',
        //     backgroundColor: '#0062cc',
        //     borderColor: '#005cbf',
        // },
        // '&:focus': {
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        // },
    },
})(Button);


export default function LocationInactive(props) {
    const uid = props.uid;
    const geoSettings = {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
    };

    const locationButtonClicked = useSelector(state => state.location_granted)
    const [geo_error, setError] = useState("NONE");
    const [cookies, setCookie] = useCookies(['user']);

    const dispatch = useDispatch()

    function locSuccess(coords) {
        dispatch({ type: 'Location_Request_Granted' })
        dispatch({ type: 'Location_Error_Reset' })

        setCookie('location_granted', true, { path: '/' });
        console.log("FIRST location granted initi" + true)

        return [coords.latitude, coords.longitude]
    };

    function locError(err) {
        console.log("error!");
        console.log(err);
        dispatch({ type: 'Location_Request_Declined' })
        setError("GEOLOCATION_DENIED")
        dispatch({ type: 'Location_Error_Denied' })

        setCookie('location_granted', false, { path: '/' });
        console.log("FIRST location Blocked initi" + false)

    }

    function requestLocation() {
        let watcher = null
        if (!navigator || !navigator.geolocation) {
            console.log("GG location not supported/requested")
            return
        } else {

            // either use watcher or subscribe to firestore and fetch location on driver coord change by X mi.
            const res = navigator.geolocation.getCurrentPosition(locSuccess, locError, geoSettings);
            // watcher = navigator.geolocation.watchPosition(locSuccess, locError, geoSettings)
        }
    }
    return (
        <div className='f_remote_bar'>
            <div className='logo_container'>
                <img className='strive_logo' src={strive_logo_name} alt="ADD" />

            </div>
            <div className='location_request_info'>
                <h1>
                    Music Remote Control
                </h1>
                <h2>
                    <u>Share Location</u> to Find Nearby Radio
                    {/* Share Location to Control Rideshare's Music */}
                </h2>

                <BootstrapButton variant="contained" color="primary" className="location_request_button" onClick={requestLocation}>
                    <i className="fas fa-music" style={{ marginRight: '8px' }}></i>
                    Connect Now
                </BootstrapButton>
                <div className='location_request_explanation'>
                    <h3>
                        Location Access REQUIRED so ONLY passengers in this rideshare control music.
                    </h3>
                </div>
                <h3>Disable Incognito & Allow Cookies for 100%</h3>
                {/* <h4>
                    Click 'Proceed' to grant access :)
                </h4> */}
                
            </div>
            {/* this header is the purble drop-down element */}
            <div className='header'>
                <div>
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>

            </div>
        </div>
    )
}