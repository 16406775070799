import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import db from './firebase.config.js';
import firebase from 'firebase/app';
import "firebase/firestore";

import { 
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Chip,
  } from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Instagram, Link, AttachMoney } from '@material-ui/icons';


import Avatar from "@material-ui/core/Avatar";

import { SvgIcon } from '@material-ui/core';

// custom
import spotify_logo from './spotify.svg'
// import { debug } from 'webpack';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 10,
        maxWidth: 1052
    },
    demo: {
        backgroundColor: theme.palette.background.paper
    }
}));




// mb have all info in firebase instead of sending Spotify POSTs from client 
function Track(props) {
    const classes = useStyles();
    const index = props.index;
    const artist = props.artist;
    const song = props.song_name;
    const track_id = props.track_id;
    const url = props.url;
    const cover_art = props.cover_art;
    const driver = props.driver;
    const passenger_id = props.passenger;
    const meta = props.meta;
    const genres = props.genres;
    const pax_session_id = props.session_id

    function SpotifyLogo() {
        return <img className='spotify_icon' src={spotify_logo} alt="ADD" />
    }
    function CoverArt(props) {
        return <img className='cover_art_icon' src={props.cover_art_url} alt="COVER_ART" />
    }
    function CoverArtWithLogo(props) {
        const handleClick = (event) => {
            event.stopPropagation();
          };
        return (
        <a href={props.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={spotifyClicked}>
            <div className="cover_art_wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                <img className='cover_art_icon' src={props.cover_art_url} alt="COVER_ART" />
                <img className="spotify_logo_icon" src={spotify_logo} alt="SPOTIFY_LOGO" style={{ position: 'absolute', top: '1px', right: '1px', width: '28px' }} />
            </div>
          </a>
        );
      }
    function HelpingHandsIcon(props) {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M16 18v3H8v-3a4 4 0 111-3.465V13a8 8 0 1015.458 0v1.535A4 4 0 0116 18zm2-10.532A4 4 0 0116 6V3h-4v3a4 4 0 11-2 3.465V11a8 8 0 1010.458 0v-1.535a4 4 0 01-2-3.465V6z" />
          </svg>
        );
    }
    function ConcertIcon(props) {
        return (
          <SvgIcon {...props}>
            <path d="M17.7,12.9l-2.6-2.6c-0.4-0.4-1-0.4-1.4,0L9.1,14.8l-1.2-1.2l6.6-6.6c0.4-0.4,1-0.4,1.4,0l2.6,2.6c0.4,0.4,0.4,1,0,1.4L17.7,12.9z M7.9,16.8c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4l0,0C8.9,16.4,8.3,16.4,7.9,16.8z M12,19.5c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,15.9,16.4,19.5,12,19.5z M12,4.5c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S15.3,4.5,12,4.5z" />
          </SvgIcon>
        );
      }
    function spotifyClicked() {
        console.log('artist clicked: ', artist)
        console.log('track_id clicked: ', track_id)
        console.log('song clicked: ', song)
        console.log('pax who clicked: ', passenger_id)

        const pax_doc = db.collection('Passengers').doc(passenger_id)
        if(pax_doc.exists){
            pax_doc.update({
                'tracksClicked' : firebase.firestore.FieldValue.arrayUnion(track_id)
            })
        }else{
            pax_doc.set({
                'tracksClicked' : firebase.firestore.FieldValue.arrayUnion(track_id)
            }, {merge: true})
        }

        db.collection('Artist_Analytics').doc(track_id).collection('passenger_id').doc(passenger_id).set({
            artist_name: artist,
            song_name: song,
            track_id: track_id,
            spotify_clicks: firebase.firestore.FieldValue.increment(1),
            driver: driver,
            passenger_id: passenger_id,
            time_clicked: firebase.firestore.FieldValue.serverTimestamp(),

        }, {merge: true})
    }

    const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists


    function pax_clicked_socials (social_clicked) {
        console.log("social clicked " + social_clicked)
        const check_pax_sesh = deepDocExists("Passengers", passenger_id, "sessions", pax_session_id)
        try{ 
            check_pax_sesh.then((check_val)=> {
                if(check_val){
                    const unix_rn = Date.now() / 1000
                    const clicked_str = social_clicked + "_clicked"
                    console.log("adding SOCIALs clicked interaction: ", pax_session_id)
                    db.collection('Passengers').doc(passenger_id).update({
                    'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('Passengers').doc(passenger_id).collection("sessions").doc(pax_session_id).update({
                        'actions':firebase.firestore.FieldValue.arrayUnion(clicked_str),
                        'interactions': firebase.firestore.FieldValue.arrayUnion({
                            'action' : 'socials_clicked',
                            'meta' : {'social_type' : social_clicked},
                            'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                        }),
                    }).then((e)=>{
                        console.log("finished updating same pax doc ", e)
                    })
                }
            })

        }catch (e) {
            console.log("failed adding socials interaction ")
        }
    }
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    // const genres = ["rap", "r&b", "hip hop"]
    // const genres = ["pop", "hip hop", "indie", "country", "r&b", "classical", "rock", "edm"]

    return (
        <>
        {
        (genres && genres[0] != 'perso' && meta && Object.keys(meta).length > 0 ) ?
            <ListItem key={index} style = {{display: 'flex'}}>
                <ExpansionPanel expanded={expanded} onChange={handleExpandClick} className="expand_panels">
                    <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ListItemAvatar  style={{ alignSelf: 'flex-start', marginRight: '4px',  }}>
                                    {/* <CoverArt cover_art_url={cover_art} /> */}
                                    <CoverArtWithLogo cover_art_url={cover_art} link={url}/>
                                </ListItemAvatar>
                                <ListItemText component={'div'} className={"song_artist_block"}
                                    primary={song}
                                    secondary={
                                    <div style={{ marginTop : '2px', maxWidth: '85vw', overflowY: 'auto' , display: 'flex', overflowX: 'auto', }}>
                                        <div>{artist}</div>
                                        <div style={{ alignSelf: 'flex-start', marginLeft: '2px', maxWidth: '75vw', display: 'flex',}}>
                                            {genres.map((genre, i) => (
                                            <Chip
                                                key={i}
                                                label={genre}
                                                variant="outlined"
                                                size="small"
                                                className="chip"
                                                style={{ marginLeft: '4px', backgroundColor: '#efefef' }}
                                            />
                                            ))}
                                        </div>  
                                    </div>
                                    }
                                />
                            </div>
                        </div>
                        {/* GENRES NEXT TO CHEVRON and vertically scrollable */}
                        {/* <div style={{ alignSelf: 'flex-start', maxHeight: '48px', overflowY: 'auto' ,  marginLeft: '4vw', overflowX: 'auto', width: '58%', }}>
                            {genres.map((genre, i) => (
                            <Chip
                                key={i}
                                label={genre}
                                variant="outlined"
                                size="small"
                                className="chip"
                                style={{ marginLeft: '4px', backgroundColor: '#efefef' }}
                            />
                            ))}
                        </div> */}


                        {/* <ListItemSecondaryAction>
                            add conditional icon button signifying smth more if they click for strive tracks w/ concert tix??
                        </ListItemSecondaryAction> */}


                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography className='expanded'>
                            {/* add any additional track info here */}
                            {/* conditionally include link-based action for Strive artists (concert tix golden eggs so if they open spotify through that link they get blessed) / queued BY PAX DETAILS ? */}
                            <div>
                                Support {artist} @ 
                            </div>
                            {meta && Object.keys(meta).length > 0 && Object.entries(meta).map(([key, value], index) => (
                                <>
                                <a key={index} href={value} target="_blank" rel="noopener noreferrer" style={{ marginRight: '16px', marginLeft: '16px' }} className='ig_icon'>
                                    {key === 'instagram' ? (
                                    <IconButton edge="end" aria-label="delete" component="a" href={value} className='ig_icon' onClick={()=>{pax_clicked_socials(key)}}>    
                                        <Instagram />
                                    </IconButton>
                                    ) : key === 'linktree' ? (
                                        <IconButton edge="end" aria-label="delete" component="a" href={value} onClick={()=>{pax_clicked_socials(key)}}>    
                                            <Link />
                                        </IconButton>
                                    ) : key === 'support' ? (
                                        <IconButton edge="end" aria-label="delete" component="a" href={value} onClick={()=>{pax_clicked_socials(key)}}>
                                            <HelpingHandsIcon />
                                        </IconButton>                                          
                                    ) : null}
                                </a>
                                </>
                            ))}
                        </Typography>

                        {/* <ListItemSecondaryAction>
                            <div className='spotify_button_wrapper' onClick={spotifyClicked}>
                                <IconButton edge="end" aria-label="delete" component="a" href={url}>
                                        <DeleteIcon />
                                        <SpotifyLogo />
                                </IconButton>
                                
                            </div>
                        </ListItemSecondaryAction> */}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </ListItem>
            :
            <ListItem key={index} style = {{display: 'flex'}}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ListItemAvatar  style={{ alignSelf: 'flex-start', marginRight: '4px',  }}>
                                    {/* <CoverArt cover_art_url={cover_art} /> */}
                                    <CoverArtWithLogo cover_art_url={cover_art} link={url}/>
                                </ListItemAvatar>
                                <ListItemText component={'div'} className={"song_artist_block"}
                                    primary={song}
                                    secondary={
                                    <div style={{ marginTop : '2px', maxWidth: '85vw', overflowY: 'auto' , display: 'flex', overflowX: 'auto', }}>
                                        <div>{artist}</div>
                                        <div style={{ alignSelf: 'flex-start', marginLeft: '2px', maxWidth: '75vw', display: 'flex',}}>
                                            {genres.map((genre, i) => (
                                            <Chip
                                                key={i}
                                                label={genre}
                                                variant="outlined"
                                                size="small"
                                                className="chip"
                                                style={{ marginLeft: '4px', backgroundColor: '#efefef' }}
                                            />
                                            ))}
                                        </div>  
                                    </div>
                                    }
                                />
                            </div>
                        </div>
                        {/* COULD MAKE SAVE/HEART BUTTON */}
                        {/* <ListItemSecondaryAction>
                            <div className='spotify_button_wrapper' onClick={spotifyClicked}>
                                <IconButton edge="end" aria-label="delete" component="a" href={url}>
                                        <SpotifyLogo />
                                </IconButton>
                                
                            </div>
                        </ListItemSecondaryAction> */}
            </ListItem>
        }
        </>
    )

}

export default Track;