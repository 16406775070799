import strive_logo from './strive.png'
import strive_logo_name from './logo_name.png'

import RemoteTabs from './Tab';
import './main.css'
import './f_style.css'

export default function Inactive(props) {
    const uid = props.uid;
	const status = props.active;
	const name = props.driver_name;
    const passenger_id = props.passenger_id
    const start = props.start
    const alias = props.alias;
    const idx = props.idx

    return (
        <div className='f_remote_bar'>
            <div className='logo_container'>
                <img className='strive_logo' src={strive_logo_name} alt="ADD" />
            </div>
            <h1 className='f_intro'>{ (alias!=="") ? alias : "Your driver"}&#39;s Strive session is inactive </h1>
            <h4 className='f_instructions'>Let { (alias!=="") ? alias : "your driver"} know to start session :)</h4>
            <div className={true ? 'nav_tabs' : 'tabs'}>
                <RemoteTabs uid={uid} active={status} passenger={passenger_id} start={start} idx={idx}/>
            </div>
            {/* this header is the purble drop-down element */}
            <div>
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
        </div>
    )
}