import React, { useState, useEffect } from 'react';
import Navbar from './Navbar.js';
import Remote from './Remote.js';
import RemoteTabs from './Tab.js'
import db from './firebase.config.js';
import './main.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

function DefaultLookup({ match }) {
    const uid = 'TyLhISupE7SFuRxhiqaVx7ofeUF2';
    const temp_status = false;

    const [name, setName] = useState('')
    const [status, setUserStatus] = useState(false)
    useEffect(() => {
        const status_interval = setInterval(() => (db.collection('Users').doc(uid).get()
          .then(snapshot => {
            setUserStatus(snapshot.data().isSessionActive);
            setName(snapshot.data().fullName);
          }))
          , 10000);
    
    
        return () => {
          clearInterval(status_interval);
        };
      }, [status]);
    
    // PERFORM QR_ID => UID Lookup and pass in UID 

    return (
        <>
            {/* <Navbar uid={uid} active={status} driver_name={name}/> */}
            <Navbar uid={uid} qr_id={"qr_id"} active={status} driver_name={name} passenger={"passenger_id"} driver_pax_proximity={false} pax_proximity={false} pax_access={""} pax_refresh={""} driver_access={""} start={""} idx={-1}/>

            {/* <div className={true ? 'nav_tabs' : 'tabs'}>
              <RemoteTabs uid={uid} active={status} />
            </div> */}
            <Remote />
        </>

    );
}

export default DefaultLookup;