import { load } from '@fingerprintjs/fingerprintjs';
import React, { useState, useEffect } from 'react';


// mb have all info in firebase instead of sending Spotify POSTs from client 
function Loading(props) {
    const [is_loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 5000)
    }, [])
    return (
        <div>
            {
                is_loading ? 
                <div className='kinetic'></div>
                : 
                null
            }
        </div>
    )
}

export default Loading;