import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import './main.css'
import './main.scss'
import './f_style.css'
import { shadows } from '@material-ui/system';

import IconButton from '@material-ui/core/IconButton';
import pause_logo from './pause.svg'
import play_logo from './play.svg'
import skip_logo from './skip.svg'
import spotify_temp from './spotify.svg'
import db from './firebase.config.js';

import CircleTimer from './CircleTimer';
import SkipTimer from './SkipTimer';
import PopupMenu from './PopupMenu.js';

import spotify_logo from './spotify.svg'

import firebase from 'firebase/app';
import "firebase/firestore";

import { useSelector, useDispatch } from 'react-redux'

const Play = ({onPlayerClick}) => {
    return (
        <div className='pause_icon'>
            <IconButton edge="end" aria-label="play" component="a" onClick={onPlayerClick}>
                <img src={play_logo} alt="PLAY" />
            </IconButton>
            {/* <Button className='button' variant="contained" color="primary"> Pause </Button> */}
        </div>
    );
}

const Pause = ({onPlayerClick}, props) => {
    return (
        <div className='pause_icon'>
                <IconButton edge="end" aria-label="pause" component="a" onClick={onPlayerClick}>
                    <img src={pause_logo} alt="PAUSE" />
                </IconButton>
            {/* <Button className='button' variant="contained" color="primary"> Pause </Button> */}
        </div>
    );
}


function Skip(props) {
    const uid = props.uid
    const prop_disabled = props.skip_disabled
    const prop_skip_after = props.skip_after

    const [disabled, setDisabled] = useState(false)
    const [skip_after, setSkipAfter] = useState(props.skip_after || 3)
    const [i_skipped, setISkipped] = useState(false)
    const dispatch = useDispatch()

    function skip() {
        db.collection('Users').doc(uid).update({
            'remoteSkip': true
        }, {merge: true})

        console.log("song skipped");
        dispatch({ type: 'Increment' })

        setDisabled(true)
        setISkipped(true)
        setTimeout(() => {
            setDisabled(false)
            setISkipped(false)

        }, 10000);

    }

    useEffect(() => {
        if(i_skipped && (disabled || prop_disabled)) {
            setSkipAfter(10)
            console.log("I SKIPPED but disabled caught as " + disabled + " but props disabled " + prop_disabled)

        }else if (!i_skipped && !disabled && prop_disabled){ //iff disabled then pax triggered so persist pax_skip_delay of 10/15s instead of skip_after sec. 
            setSkipAfter(prop_skip_after)
            // setDisabled(true)
            setTimeout(() => setDisabled(false), Number(prop_skip_after) * 1000);
            console.log("I DIDNT skip but disabled caught as " + disabled + " but props disabled " + prop_disabled)

        }
    }, [disabled, prop_disabled])
    

    return (
        <div className='skip_icon'>
           
            <IconButton disabled={disabled} edge="end" aria-label="skip" component="a" onClick={skip}>
            {
                ((!disabled && !prop_disabled)) ? <img src={skip_logo} alt="SKIP" /> : (i_skipped) ? <SkipTimer sec={10} /> : <SkipTimer sec={skip_after} />
                // <div className='hourglass' > 
                //     <div className='top' > 
                    
                //     </div> 
                //     <div className='bottom' > 
                    
                //     </div> 
                // </div> 
            }
            </IconButton>
           
            {/* <Button className='button' variant="contained" color="primary"> Pause </Button> */}
        </div>
    );
}

function CurrentArt(props) {
    const uid = props.uid
    const pax_id = props.pax_id
    const refreshToken = props.refresh
    const vol_control = props.vol_control

    // const [CDN, setCDN] = useState("https://i.scdn.co/image/ab67616d0000b273148e1f0715c7e9c1034d722c")
    // const [trackName, setTrackName] = useState("Song A");
    // const [track_url, setTrackURL] = useState("");
    // const [artistName, setArtistName] = useState("Artist A");

    const CDN = useSelector(state => state.cdn);
    const track_url = useSelector(state => state.url);
    const artistName = useSelector(state => state.artist);

    const [skip_disabled, setSkipDisabled] = useState(false)

    const [pauseClicked, setPauseClicked] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

    const dispatch = useDispatch()
    const prev_song = useSelector(state => state.song);
    
    function pause_clicked(){
        console.log('pause clicked', pauseClicked)
        setPauseClicked(true)
        setButtonClicked(true)
    }
    function play_clicked(){
        console.log('play clicked', pauseClicked)
        setPauseClicked(false)
        setButtonClicked(true)
    }

    function spotifyClicked() {
        console.log('artist clicked: ', artistName)
        console.log('song clicked: ', prev_song)
        console.log('pax who clicked: ', pax_id)

        var curr_track_parts = track_url.split("/") 
        var curr_track_id_utm = curr_track_parts[curr_track_parts.length - 1]
        var curr_track_id_parts = curr_track_id_utm.split("?")
        var curr_track_id = curr_track_id_parts[0]

        console.log('track_id clicked: ', curr_track_id)

        const pax_doc = db.collection('Passengers').doc(pax_id)
        if(pax_doc.exists){
            pax_doc.update({
                'tracksClicked' : firebase.firestore.FieldValue.arrayUnion(curr_track_id)
            })
        }else{
            pax_doc.set({
                'tracksClicked' : firebase.firestore.FieldValue.arrayUnion(curr_track_id)
            }, {merge: true})
        }

        db.collection('Artist_Analytics').doc(curr_track_id).collection('passenger_id').doc(pax_id).set({
            artist_name: artistName,
            song_name: prev_song,
            track_id: curr_track_id,
            spotify_clicks: firebase.firestore.FieldValue.increment(1),
            driver: uid,
            passenger_id: pax_id,
            time_clicked: firebase.firestore.FieldValue.serverTimestamp(),
        }, {merge: true})
    }

    function CoverArtWithLogo(props) {
        const handleClick = (event) => {
            event.stopPropagation();
          };
        return (
        <a href={props.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={spotifyClicked}>
            <div className="cover_art_wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                <img className='current_art' src={props.img_src} alt="COVER_ART" />
                <img className="spotify_logo_icon" src={spotify_logo} alt="SPOTIFY_LOGO" style={{ position: 'absolute', top: '-2px', right: '-2px', width: '34px' }} />
            </div>
          </a>
        );
      }

    useEffect(() => {
        if (uid != "") {
            // db.collection('Users').doc(uid).onSnapshot(snapshot => { //TODO: REPLACE SNAPSHOT W/ REDUX
            //     const curr_cdn = snapshot.data().currentSession.currentTrack.coverArt
            //     const curr_song = snapshot.data().currentSession.currentTrack.trackName;
            //     const artist_name = snapshot.data().currentSession.currentTrack.artistName;
            //     const url = snapshot.data().currentSession.currentTrack.url+"?utm_campaign=com.app";

            //     setCDN(curr_cdn)
            //     setTrackName(curr_song)
            //     setArtistName(artist_name)
            //     setTrackURL(url)

                // if (curr_song != prev_song) {
                //     console.log('prev song', prev_song)
                //     console.log('current song', curr_song)
                //     //set skip_disabled to true 
                //     // setSkipDisabled(true)
                //     // setTimeout(() => setSkipDisabled(false), 10000);

                //     // dispatch({ type: "Song_Update", payload: { song: curr_song } });
                //     // dispatch({ type: "Increment" });

                // }
            // })
            
            setSkipDisabled(true)
            setTimeout(() => setSkipDisabled(false), 3000);

        }


    }, [uid, prev_song]);

    useEffect(()=>{
         //DISABLE pause ability in retail settings ez
         if(!pauseClicked && buttonClicked){
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' , 'Authorization': "Bearer " + refreshToken},
                // body: JSON.stringify({ title: 'React PUT Request Example' })
            };
            fetch('https://api.spotify.com/v1/me/player/play', requestOptions)
            setButtonClicked(false)
        
        }else if(pauseClicked && buttonClicked){
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' , 'Authorization': "Bearer " + refreshToken},
                // body: JSON.stringify({ title: 'React PUT Request Example' })
            };
            fetch('https://api.spotify.com/v1/me/player/pause', requestOptions)
            setButtonClicked(false)
        }

    }, [pauseClicked])

    return (
        <div className='remote_track_info'>
            {/* <a href={track_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={spotifyClicked}>
                <img className='current_art' src={CDN} alt="COVER_ART" />
            </a> */}
            <CoverArtWithLogo link={track_url} img_src={CDN}/>

            <div className='remote_buttons_div'>
                <div className='remote_top'>
                    <marquee scrollamount="4">
                        <h1 className='track_scrolling_view'>{prev_song}</h1>
                    </marquee>
                </div>
                <div className='remote_bottom'>
                    <div className='track_info'>
                        {/* <marquee scrollamount="4"><h1 className='track_scrolling_view'>{trackName}</h1></marquee> */}
                        <h2>{artistName}</h2>
                    </div>
                    <div className='buttons'>
                        {!pauseClicked ? <Pause onPlayerClick={pause_clicked} refresh={refreshToken}/> : <Play onPlayerClick={play_clicked} refresh={refreshToken}/>}
                        <Skip uid={uid} skip_disabled={skip_disabled} skip_after={3}/>
                        {/* <PopupMenu uid={uid} /> */}
                    </div>
                    <div className='buttons'>
                        <PopupMenu uid={uid} vol_control={vol_control}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


function Remote(props) {
    const uid = props.uid;
    const refreshToken = props.refresh;
    const vol_control = props.vol_control;
    const pax_id = props.passenger


    return (
        <div className="anchor">
            <div className='remote'>
                <CurrentArt uid={uid} refresh={refreshToken} vol_control={vol_control} pax_id = {pax_id}/>
                <div className='remote_waves'>
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g class="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
            
        </div>
    );
    // }
}

export default Remote;