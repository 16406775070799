import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import db from './firebase.config.js';
import './main.css'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from './Table.js';
import Genres from './Genres.js';
import Popup from 'reactjs-popup';
import Button from '@material-ui/core/Button';

import { shadows } from '@mui/system';

import { useSelector, useDispatch } from 'react-redux'
import firebase from 'firebase/app';

import 'reactjs-popup/dist/index.css';
import spotify_logo from './spotify.svg'


// import authHandler from "./spotifyAuth";
// import { SpotifyAuth, Scopes } from 'react-spotify-auth'


const BootstrapButton = withStyles({
    root: {
        boxShadow: 2,
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        color: '#ffffff',
        // border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#6C72FF',
        fontFamily: [
            "San Francisco", 
            'Helvetica', 
            'Arial',
            '-apple-system',
        ].join(','),
        '&:hover': {
            // backgroundColor: '#9EA2FF',
            // borderColor: '#8F94FF',
            boxShadow: 2,
        },
    },
})(Button);

const GenreTooltip = () => (
    <Popup
      trigger={open => (
        <BootstrapButton variant="contained" className="tool_button">What's this?</BootstrapButton>
      )}
      position="bottom center"
      closeOnDocumentClick
    >
      <span> Request music from your favorite genres! After your requested song is queued and played, you can request again :) </span>
    </Popup>
  );

const HistoryTooltip = () => (
    <Popup
      trigger={open => (
        <BootstrapButton variant="contained" className="tool_button"> ? </BootstrapButton>
      )}
      position="top center"
      closeOnDocumentClick
    >
      <span> Recently played songs for you to explore/add to your playlist </span>
    </Popup>
  );

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function RemoteTabs(props) {
    const uid = props.uid
    const idx = props.idx
    const queued_idx = props.queued_idx 

    const start = props.start
    const status = props.active
    const pax = props.passenger
    const pax_proximity = props.pax_proximity
    const driver_pax_proximity = props.driver_pax_proximity
    const qr_id = props.qr_id
    const queue_control = props.queue_control
    const vol_control = props.vol_control

    const genre_dist = props.genre_dist
    const pax_access = props.pax_access;
	const driver_access = props.driver_access
    const circle_delay = props.circle_delay

    const dispatch = useDispatch();
	const session_id = useSelector(state => state.session_id)

    const [value, setValue] = React.useState(0);
    const [accessToken, setAccessToken] = React.useState("");

    const [genreClick, setGenreClick] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function clickedGenre() {
        console.log("clicked genre tab")
        // setHistoryClick(false)
        setGenreClick(true)
    }

    useEffect(()=>{
        console.log('session_id', session_id)
        //could test Passengers analytics here too
    }, [session_id])

    return (pax_proximity || driver_pax_proximity)  ? (
        <>
            {/* <BootstrapButton variant="contained" color="primary" className="location_request_button" onClick={auth_with_spotify}>
                Login <SpotifyLogo />
            </BootstrapButton> */}
            {/* <HistoryTooltip /> */}
            {/* <AppBar className="appbar_tab" position="static">

                <Tabs className="tab" value={value} onChange={handleChange} variant="fullWidth" TabIndicatorProps={{ style: { background: '#FFFFFF' } }}>
                    <Tab className="tab_label" onClick={clickedGenre} label={<span style={{ color: '#FFFFFF' }}>Queue</span>} {...a11yProps(0)} textColorPrimary="white" />
                    <Tab className="tab_label" label={<span style={{ color: '#FFFFFF' }}>History</span>} {...a11yProps(1)} />
                </Tabs>
            </AppBar> */}

            {/* <div className="all_tabs"> */}
                <div className= {true ? "nav_tabs" : "tabs"}>
                    <Tabs className="tab" value={value} onChange={handleChange} variant="fullWidth" TabIndicatorProps={{ style: { background: '#FFFFFF' } }} sx={{ boxShadow: 3 }}>
                            <Tab className="tab_label" onClick={clickedGenre} label={<span style={{ color: '#FFFFFF' }}>Queue</span>} {...a11yProps(0)} textColorPrimary="white" />
                            <Tab className="tab_label" label={<span style={{ color: '#FFFFFF' }}>History</span>} {...a11yProps(1)} />
                    </Tabs>
                </div>
                {/*  add component={'span'} to TabPanel below */}
                <div className='tab_panel'>
                    <TabPanel value={value} index={0}>
                        <div class="queue_container">
                            <Genres uid={uid} pax_proximity={pax_proximity} driver_access={driver_access} pax_access={pax_access} passenger={pax} start={start} idx={idx} genre_dist={genre_dist} circle_delay={circle_delay} queue_control={queue_control} vol_control={vol_control} queued_idx={queued_idx}/>
                            {/* <GenreTooltip /> */}
                        </div>
                    </TabPanel>
                </div>

                <div className='tab_panel_2'>
                    <TabPanel value={value} index={1}>
                    <div class="table_container">
                        <Table uid={uid} active={status} pax_proximity={pax_proximity} driver_proximity={driver_pax_proximity} passenger={pax} start={start} idx={idx} queued_idx={queued_idx} geo_locked={false}/>
                    </div>
                    </TabPanel>
                </div>
            {/* </div> */}
        </>
    ) : (
        <>
            <AppBar className="failed_tab" position="static">
                <Tabs className="tab" value={value} onChange={handleChange} variant="fullWidth" TabIndicatorProps={{ style: { background: '#6C72FF' } }}>
                    <Tab className="tab_label" label={<span style={{ color: '#FFFFFF' }}>History</span>} {...a11yProps(1)} />
                </Tabs>
            </AppBar>

            <div className='tab_panel_f'>
                <TabPanel value={value} index={0}>
                    <Table uid={uid} active={status} pax_proximity={pax_proximity} driver_proximity={driver_pax_proximity} passenger={pax} start={start} idx={idx} queued_idx={queued_idx} geo_locked={true}/>
                </TabPanel>
            </div>

        </>

    );
}

// function MyTab(){

//     return <h1 id='title'>Recently Listened to</h1>;

// }

// export default MyTab;