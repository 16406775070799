import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

function init_firebase(){
  const firebaseConfig = {
    apiKey: "AIzaSyCsqNAMg9gisUcD9qcezu3qdxtUcwZ88AA",
    authDomain: "strive-9a245.firebaseapp.com",
    databaseURL: "https://strive-9a245.firebaseio.com",
    projectId: "strive-9a245",
    storageBucket: "strive-9a245.appspot.com",
    messagingSenderId: "454359463466",
    appId: "1:454359463466:web:6668d215a50d198ecb7553",
    measurementId: "G-4K7DXS81D8"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

}

init_firebase();
const db = firebase.firestore()

export default db
