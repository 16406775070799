import React, { useState, useEffect } from 'react';
import db from './firebase.config.js';

import firebase from 'firebase/app';
import "firebase/firestore";

import './main.css';
import Track from './Track.js';

import Grid from "@material-ui/core/Grid";

import { FixedSizeList } from 'react-window';
import List from "@material-ui/core/List";

import {useSelector, useDispatch} from 'react-redux';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Loading from './Loading.js';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
  
	return result;
  };

  
const getItemStyle = (isDragging, draggableStyle) => ({
// styles we need to apply on draggables
...draggableStyle,

...(isDragging && {
	background: "rgb(235,235,235)"
})
});

const getListStyle = isDraggingOver => ({
//background: isDraggingOver ? 'lightblue' : 'lightgrey',
});



async function GetTracks(uid, start, curr_idx) {
	// const [tracks, setTrackList] = useState([]);

	async function getAll(uid, start, curr_idx) {
		var temp_tracks = [];
		// var temp_tracks = [];

		var temp_artist = "A Boogie Wit Da Hoodie";
		var artist_id = '31W5EY0aAly4Qieq6OFu6I'
		var song_name = "24 Hours (feat. Lil Durk)";
		var cover_art = "https://i.scdn.co/image/ab67616d0000b273e05fa7536d30fd6d8955d4ee";
		var track_id = "0YH1xT3Z7wR6dZyaCwSziK";

		var genres = ["none"]
		// const streamsRef = db.collection('Streams').doc(uid).collection('StreamData').orderBy("date", "desc").limit(15);
		// console.log(uid)
		// console.log("start time " + start)

		// const streamsRef = db.collection('Impressions').doc(uid).collection('impressionData').orderBy("date", "desc").limit(15);
		// const streamsRef = db.collection('Sessions').doc(uid).collection('sessionData').document(start).collection("tracks") //.orderBy("og_idx", "asc").limit(15);
		
		// const snapshot = await db.collection('Impressions').doc(uid).collection('impressionData').orderBy("date", "desc").limit(15).get();
		const snapshot = await db.collection('Sessions').doc(uid).collection('sessionData').doc(start).collection("tracks").where("og_idx", ">", curr_idx).orderBy("og_idx", "asc").limit(30).get();

		snapshot.docs.map(async doc => {
			// track_id = doc.data().trackID;

			// var temp_url = "https://open.spotify.com/track/" + track_id + "?utm_campaign=com.app";

			// artist_id = doc.data().artistID;
			// temp_artist = doc.data().artistName;
			// song_name = doc.data().trackName;
			// cover_art = doc.data().coverArt;

			
			track_id = doc.data().track_id;

			var temp_url = "https://open.spotify.com/track/" + track_id + "?utm_campaign=com.app";

			artist_id = doc.data().artist_id;
			temp_artist = doc.data().artist;
			song_name = doc.data().track_name;
			cover_art = doc.data().cover_art;

			genres = doc.data().genres

			const temp_track = {
				track_id: track_id,
				song_name: song_name,
				artist: temp_artist,
				url: temp_url,
				cover_art: cover_art,
				genres: genres,
				meta : doc.data().artist_meta ? doc.data().artist_meta : {},
			}
			// console.log(temp_track);
			temp_tracks.push(temp_track);
		});
		// debugger

		return temp_tracks;
	}

	if(start && curr_idx){
		var to_ret = await getAll(uid, start, curr_idx);
		// console.log(to_ret)
		return to_ret;
	}
	
	// return tracks;

}

//add logic to only get T-1hr of streams/latest 100 played
const DragTable = (props) => {
	const uid = String(props.uid);
	const start = String(props.start);
	const curr_idx = props.idx;
	const queued_idx = props.queued_idx;

	// const tabClicked = props.tabclick
	const pax = String(props.pax);
	const proximity = Boolean(props.pax_proximity)

	const skip_count = useSelector(state => state.count)
	const session_id = useSelector(state => state.session_id)

	const [status, setStatus] = useState(Boolean(props.active))

	const [tracks, setTracks] = useState({
		track_list: []
	});

	const onDragEnd = (result) => {
		if (!result.destination) return;
		if (result.destination.index === result.source.index) return;
		const projects = reorder(
			tracks.track_list, // project is state 
			result.source.index,
			result.destination.index
		);
		//store reordered state.
		setTracks({ track_list: tracks.track_list })
		// setTracks(tracks)
	}
	async function fetch_tracks() {
		const tmp_idx = curr_idx - queued_idx
		GetTracks(uid, start, tmp_idx).then((temp_tracks)=>{
			setTracks({ track_list: temp_tracks })
		})
		// const temp_tracks = await GetTracks(uid);
		// setTracks(({ track_list }) => ({ track_list: temp_tracks }));
		// setTracks({...tracks, track_list: temp_tracks});
	}
	const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists
	const docExists = async (docName, docId) => (await db.collection(docName).doc(docId).get()).exists

	useEffect(()=>{
		console.log("BUG W? RENDERING is session_id being triggered?" + session_id	)
        if(session_id != "" && pax && pax != ""){
			const unix_rn = Date.now() / 1000
            if (proximity && status){
				const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
				const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", session_id)
				try{
					pax_sesh_exists.then((check_val) => {
						if(check_val){
							console.log("adding interaction queue_tab_view")
							db.collection('Passengers').doc(pax).update({
								'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
							})

							pax_sesh_doc_ref.update({
								'actions':firebase.firestore.FieldValue.arrayUnion('queue_view'),
								'interactions': firebase.firestore.FieldValue.arrayUnion({
									'action' : 'queue_view',
									'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
								}),
							}).then((e)=>{
								console.log("finished updating " + e)
							})
						}else{
							console.log("PAX_SESSIONS doesn't exist yet to add queue_view (infer same as session_START")
						}
					})
					
				}catch (e){
					console.log(e)
				}
            }else if (!proximity && status){
                const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
				console.log("adding interaction rideendedonqueue")
				const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", session_id)
				try{
					pax_sesh_exists.then((check_val) => {
						if(check_val){
							pax_sesh_doc_ref.update({	
								'actions':firebase.firestore.FieldValue.arrayUnion('ride_ended_onqueue'),
								'interactions': firebase.firestore.FieldValue.arrayUnion({
									'action' : 'ride_ended_onqueue',
									'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
								}),
								'time_locked': firebase.firestore.FieldValue.serverTimestamp(),
								
							}).then((e)=>{
								console.log("finished updating " + e)
							})
						}else{
							console.log("PAX_SESSIONS doesn't exist yet to add rideendedonqueue")

						}
					})
					
				}catch (e){
					console.log("tried adding rideendedonqueue but e " + e)
				}
            }         
        }
	}, [session_id, pax, status])
	
	useEffect(() => {
		// console.log(skip_count)
		// console.log(tracks.track_list)
		if(uid!="" && start && curr_idx){
			fetch_tracks()
		}

		// return ()=>{
		// 	if(pax != "" && session_id != ""){
		// 		const unix_rn = Date.now() / 1000
		// 		console.log("return switching away from queue_tab_view")

		// 		const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
		// 		pax_sesh_doc_ref.set({
		//			'actions':firebase.firestore.FieldValue.arrayUnion('tab_switched_from_queue'),
		// 			'interactions': firebase.firestore.FieldValue.arrayUnion({
		// 				'action' : 'tab_switched_from_queue',
		// 				'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
		// 			}),
		// 		}, {merge:true}).then((e)=>{
		// 			console.log("finished updating after switching from tab queue" + e)
		// 		})
		// 	}
		// }
	}, [skip_count, start, uid, curr_idx, status]) //uid


	try {
			if (tracks.track_list.length > 0) {
				// debugger
				return (
					<div>
						<Grid className="table" container spacing={0.5} alignItems="center" justify="center">
							<Grid item xs={12} md={12} height="100%">
								<List component={'div'} className='queue_list_view' height="100%">
									{/* {_render_tracks()} */}
									{tracks && tracks.track_list.map((track, index) => 
									<div>
										<Track
											track_id={track.track_id}
											artist={track.artist}
											song_name={track.song_name}
											url={track.url}
											cover_art={track.cover_art} 
											driver={uid}
											passenger={pax} 
											index={index}
											genres={track.genres}
											meta={track.meta ? track.meta : {}}
											session_id={session_id}
										/>
									</div>)}
								</List>
								
								{/* <DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="list">
										{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											style={{ border: "1px solid #242424", opacity: 0.5, borderRadius: "5px" }}
										>
											{tracks && tracks.track_list.map((track, index) =>
												<Draggable draggableId={track.song_name} key={track.track_id} index={index}>
													{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<Track
															track_id={track.track_id}
															artist={track.artist}
															song_name={track.song_name}
															url={track.url}
															cover_art={track.cover_art} 
															driver={uid}
															passenger={pax} />
													</div>
													)}
												</Draggable>)}
											{provided.placeholder}
										</div>
										)}
									</Droppable>
								</DragDropContext> */}

							</Grid>
						</Grid>

					</div>
				)
			}
			else {
				// console.log("LOADING")
				return (
					<div className='loading_view'>
							{/* <div className='kinetic'></div> */}
							<Loading />
					</div>
				)
			}
	}
	catch (e) { console.log(e) }

}

export default DragTable;