import React, { useState, useEffect } from 'react';
import db from './firebase.config.js';

import firebase from 'firebase/app';
import "firebase/firestore";

import './main.css';
import Track from './Track.js';

import Grid from "@material-ui/core/Grid";

import { FixedSizeList } from 'react-window';
import List from "@material-ui/core/List";

import {useSelector, useDispatch} from 'react-redux';
import Loading from './Loading.js';

async function GetTracks(uid, start, curr_idx) {
	// const [tracks, setTrackList] = useState([]);

	async function getAll(uid, start, curr_idx) {
		var temp_tracks = [];
		// var temp_tracks = [];

		var temp_artist = "A Boogie Wit Da Hoodie";
		var artist_id = '31W5EY0aAly4Qieq6OFu6I'
		var song_name = "24 Hours (feat. Lil Durk)";
		var cover_art = "https://i.scdn.co/image/ab67616d0000b273e05fa7536d30fd6d8955d4ee";
		var track_id = "0YH1xT3Z7wR6dZyaCwSziK";
		var genres = ["none"]

		const snapshot = await db.collection('Sessions').doc(uid).collection('sessionData').doc(start).collection("tracks").where("og_idx", "<=", curr_idx).orderBy("og_idx", "desc").limit(30).get();
		// const streamsRef = db.collection('Impressions').doc(uid).collection('impressionData').orderBy("date", "desc").limit(15);
		// const snapshot = await streamsRef.get();

		snapshot.docs.map(async doc => {
			// track_id = doc.data().trackID;

			// var temp_url = "https://open.spotify.com/track/" + track_id + "?utm_campaign=com.app";

			// artist_id = doc.data().artistID;
			// temp_artist = doc.data().artistName;
			// song_name = doc.data().trackName;
			// cover_art = doc.data().coverArt;

			track_id = doc.data().track_id;

			var temp_url = "https://open.spotify.com/track/" + track_id + "?utm_campaign=com.app";

			artist_id = doc.data().artist_id;
			temp_artist = doc.data().artist;
			song_name = doc.data().track_name;
			cover_art = doc.data().cover_art;
			
			genres = doc.data().genres

			const temp_track = {
				track_id: track_id,
				song_name: song_name,
				artist: temp_artist,
				url: temp_url,
				cover_art: cover_art,
				genres: genres,
				meta : doc.data().artist_meta ? doc.data().artist_meta : {},

			}
			// console.log(temp_track);
			temp_tracks.push(temp_track);
		});
		// debugger

		return temp_tracks;
	}


	if(start && curr_idx){
		var to_ret = await getAll(uid, start, curr_idx);
		// console.log(to_ret)
		return to_ret;
	}
	// return tracks;

}

//add logic to only get T-1hr of streams/latest 100 played
const Table = (props) => {
	const uid = String(props.uid);
	// const tabClicked = props.tabclick
	const locked = props.geo_locked

	const start = String(props.start);
	const curr_idx = props.idx;
	const queued_idx = props.queued_idx

	const pax = String(props.passenger);
	const proximity = Boolean(props.pax_proximity)
	const driver_proximity = Boolean(props.driver_proximity)

	const skip_count = useSelector(state => state.count)
	const session_id = useSelector(state => state.session_id)

	const [status, setStatus] = useState(Boolean(props.active))

	const [tracks, setTracks] = useState({
		track_list: []
	});
	  
	async function fetch_tracks() {
		const tmp_idx = curr_idx - queued_idx
		GetTracks(uid, start, curr_idx).then((temp_tracks)=>{
			setTracks({ track_list: temp_tracks })
		})
		// const temp_tracks = await GetTracks(uid);
		// setTracks(({ track_list }) => ({ track_list: temp_tracks }));
		// setTracks({...tracks, track_list: temp_tracks});
	}
	const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists

	useEffect(()=>{
		// console.log("BUG W? RENDERING is session_id being triggered?")
        if(session_id != "" && pax!=""){
			const unix_rn = Date.now() / 1000
			if ((proximity || driver_proximity) && status){
				const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
				const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", session_id)
				try{
					pax_sesh_exists.then((check_val) => {
						if(check_val){
							console.log("updating history_tab_view")
							db.collection('Passengers').doc(pax).update({
								'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
							})
							pax_sesh_doc_ref.update({
								'actions':firebase.firestore.FieldValue.arrayUnion('history_tab_view'),
								'interactions': firebase.firestore.FieldValue.arrayUnion({
									'action' : 'history_tab_view',
									'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
								}),
							}).then((e)=>{
								console.log("finished updating pax doc ", e)
							})
						}else{
							console.log("PAX_SESSIONS doesn't exist yet to add history_tab_view (~starting but proximity locked)")
						}
					})
				}catch (e) {
					console.log("tried adding history_tab_view but e " + e)
				}

            }else if ((!proximity && !driver_proximity) && status){
				const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
				const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", session_id)
				try{
					pax_sesh_exists.then((check_val) => {
						if(check_val){
							console.log("updating ride_ended_onhistory")

							pax_sesh_doc_ref.update({
								'actions':firebase.firestore.FieldValue.arrayUnion('proximity_locked_ride_ended_onhistory'),
								'interactions': firebase.firestore.FieldValue.arrayUnion({
									'action' : 'proximity_locked_ride_ended_onhistory',
									'time' : unix_rn // firebase.firestore.FieldValue.serverTimestamp(),
								}),
								'time_ended': firebase.firestore.FieldValue.serverTimestamp(),
							}).then((e)=>{
								console.log("finished updating pax doc after proximity locked ", e)
							})
						}
					})
				}catch (e) {
					console.log("tried adding ride_ended_onhistory but e " + e)
				}
            }       
        }

		return ()=>{
			console.log("cleared table but prox " + proximity + " and driver prox " + driver_proximity)
			if(pax && pax != "" && session_id != ""){
				const unix_rn = Date.now() / 1000
				const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", session_id)
				try{
					pax_sesh_exists.then((check_val) => {
						if(check_val){
							const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(session_id)
							var action = (locked) ? 'UNLOCKED_geo_table' : 'switched_from_history'
							console.log("updating interactions with " + action)

							pax_sesh_doc_ref.update({
								'actions':firebase.firestore.FieldValue.arrayUnion(action),
								'interactions': firebase.firestore.FieldValue.arrayUnion({
									'action' : action,
									'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
								}),
							}).then((e)=>{
								console.log("finished updating after switching from history tab" + e)
							})
						}else{
							console.log('no pax_sesh doc to update tab_switched_from_history, too early')

						}
						
					})
				}catch (e) {
					console.log('tried updating tab_switched_from_history too early but exception ' + e)
				}
			}
		}
	}, [session_id, status])
	
	useEffect(() => {
		console.log("skip_count: " + skip_count)
		// console.log(tracks.track_list)
		if(uid!="" && start && curr_idx){
			fetch_tracks()
		}
		return ()=>{
		}
	}, [skip_count, uid, start, status, curr_idx])

	try {
		//commented out to let history show to pax on session not active as well
		// if (status) {
			if (tracks.track_list.length > 0) {
				// debugger
				return (
					<div className="table_div">
						<Grid className="table" container spacing={2} alignItems="center" justify="center">
							<Grid item xs={12} md={12} height="100%">
								<div>
									<List component={'div'} className={(status && (proximity || driver_proximity)) ? 'list_view' : 'list_view_f'} height="100%">
										{/* {_render_tracks()} */}
										{tracks && tracks.track_list.map((track, index) => 
											<div>
												<Track
													track_id={track.track_id}
													artist={track.artist}
													song_name={track.song_name}
													url={track.url}
													cover_art={track.cover_art} 
													driver={uid}
													passenger={pax} 
													genres={track.genres}
													index={index}
													meta={track.meta ? track.meta : {}}
													session_id={session_id}

													/>
											</div>)
										}
									</List>
								</div>
							</Grid>
						</Grid>

					</div>
				)
			}
			else {
				// console.log("LOADING")
				return (
					<div className='loading_view'>
						<Loading />
					</div>
				)
			}
	}
	catch (e) { console.log(e) }

}

export default Table;