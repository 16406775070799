import React, { useState, useEffect, } from 'react';
import { Modal, makeStyles } from '@material-ui/core';
// import { Chart } from 'chart.js';
// import Chart from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import BarChartIcon from '@material-ui/icons/BarChart';
import { LinearScale } from 'chart.js';

import ChartJS from 'chart.js/auto';

ChartJS.register(LinearScale);


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const GraphModal = ({ votes }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const chartRef = React.createRef();
  const chartData = {
    labels: ["rap", "hip hop", "pop", "r&b", "rock", "edm", "indie", "classical", "country"],
    datasets: [
      {
        label: 'Next Song Genre % by Vote',
        data: votes,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let chart;
    if (open) {
        console.log("chart should be open with votes " + votes)
        // chart = new Chart(chartRef.current, {
        // chart = new Chart(document.getElementById('chart-canvas'), {
        //     type: 'bar',
        //     data: chartData,
        //     options: {
        //         responsive: true,
        //         scales: {
        //             y: {
        //               beginAtZero: true
        //             }
        //           }
        //     },
        // });
    }

    return () => {
        // if (chart) {
        //     chart.destroy();
        // }
    };

  }, [open]);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
  };


  return (
    <>
      <button className="genre_vote_button" type="button" onClick={handleOpen}>
        {/* View Genre Votes */}
        <BarChartIcon />
      </button>

        <Modal open={open} onClose={handleClose} className={classes.modal}>
        {/* <div> */}
        { (open && votes) ?
          <>
          {/* <canvas ref={chartRef} className="chart_canvas" id="chart-canvas" /> */}
          <Chart
            type={"bar"}
            options={options}
            data={chartData}
            className={'chart_canvas'}
          />
          </>
           : 
           <div className='kinetic'></div>
          }
        {/* </div> */}
      </Modal>

    </>
  );
};

export default GraphModal;