import React, { useState, useEffect, KeyboardEvent, MouseEvent } from 'react';

import Inactive from './Inactive.js';
import db from './firebase.config.js';
import Navbar from './Navbar.js';
import Remote from './Remote.js';
import RideEnded from './RideEnded.js'

import { useSelector, useDispatch } from 'react-redux'

import { useCookies } from 'react-cookie';

import FingerprintJS from '@fingerprintjs/fingerprintjs'

import firebase from 'firebase/app';
import "firebase/firestore";

import './main.css'

import Button from "@mui/material/Button";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { DataObjectSharp } from '@mui/icons-material';


function Lookup({ match }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const qr_id = match.params.qr_id || cookies.qr_id; 
    const location_granted_cookie = Boolean(cookies.location_granted)


    const [uidFetched, setFetched] = useState(false)
    const [firstLoad, setFirstLoad] = useState(false)
    const [uid, setUID] = useState("")
    const [start, setStart] = useState(-1) //driver session ID

    const [snapshots, setSnapshots] = useState(0)

    const [location, setLocation] = useState({});
    const [speed, setSpeed] = useState();
    const [driver_accuracy, setDriverAccuracy] = useState();
    const [paxLong, setPaxLong] = useState();
    const [paxLat, setPaxLat] = useState();
    const [paxProximity, setPaxProximity] = useState(true);
    const [driverPaxProximity, setDriverPaxProximity] = useState(false);
    const [driverPaxQueue, setDriverPaxQueue] = useState(true);
    const [driverPaxVolume, setDriverPaxVolume] = useState(true);
    const [alias, setAlias] = useState("");
    const [og_idx, setOGIDX] = useState(-1);
    const [pax_queued_idx, setQueueIDX] = useState(-1);

    const [driverLong, setDriverLong] = useState();
    const [driverLat, setDriverLat] = useState();

    const [driverTips, setDriverTips] = useState({});


    //only set driver pref genres as possible options
    const [genre_dist, setGenreDist] = useState({"rap":0, "hip hop":0, "pop":0, "r&b":0, "rock":0, "edm":0, "indie":0, "classical":0, "country":0})


    const [name, setName] = useState('')
    const [refreshToken, setRefreshToken] = useState('')
    const [status, setUserStatus] = useState(true)

    const [CDN, setCDN] = useState("https://i.scdn.co/image/ab67616d0000b273148e1f0715c7e9c1034d722c")
    const [trackName, setTrackName] = useState("Song A");
    const [last_queued_song, setLastQueued] = useState("")
    const [track_url, setTrackURL] = useState("");
    const [artist_name, setArtistName] = useState("Artist A");

    const [pax_sesh_id, setPaxSeshID] = useState("");
    const [passenger_id, setPassengerID] = useState("");
    const [pax_email, setPaxEmail] = useState("");
    const [pax_name, setPaxName] = useState("");
    const [pax_guest_name, setPaxGuestName] = useState("");
    const [pax_img, setPaxImg] = useState("");
    const [pax_queue, setPaxQueue] = useState({});
    const [live_pax, setLivePax] = useState({})
    const [pax_uri, setPaxURI] = useState("");
    const [circle_delay, setCircleDelayDB] = useState(false);

    const location_granted = useSelector(state=> state.location_granted)
    const coords = useSelector(state=> state.coords)
    const pax_redux_refresh = useSelector(state=> state.pax_refresh)

    const prev_song = useSelector(state => state.song);

    const pax_access_cookie = cookies.access_token || "NO_ACCESS"
    const pax_refresh_cookie = cookies.refresh_token || "NO_REFRESH"
    const passenger_id_cookie = cookies.passenger_id || "" // fingerprint prior to spot auth
    const session_id_cookie = cookies.session_id || "" // fingerprint prior to spot auth

    const guest_pax_name = cookies.guest_pax_name || ""


    const [drawer, setDrawer] = useState(false)
    const [anchor, setAnchor] = useState('right')
    const fin_anchor = "right";
    
    const geoSettings = {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
    };

    
    const toggleDrawer = (anchor, open) => () => {
        // setState({ ...state, [anchor]: open });
        setDrawer(open);
      };


    const dispatch = useDispatch()

    const locSuccess = position => {
        const { latitude, longitude } = position.coords;

        setLocation({
            latitude, longitude
        });

        

        try {
            // const latitude = position.coords.latitude;
            // const longitude = position.coords.longitude;
            setPaxLat(latitude)
            setPaxLong(longitude)
            // console.error('successfully in locSuccess:', latitude);

          } catch (err) {
            console.error('Error in locSuccess:', err);
          }

        // if(passenger_id != "") { 
        //     db.collection('Users').doc(uid).update({
        //         // ['currentSession.location.pax_latitude'] : latitude,
        //         // ['currentSession.location.pax_longitude']: longitude,
        //         // ['currentSession.location.pax_update_time']: firebase.firestore.FieldValue.serverTimestamp(),
        //         // ['currentSession.location.pax_accuracy']: position.coords.accuracy,
        //         ['live_pax_coords.'+passenger_id+'.latitude'] : latitude,
        //         ['live_pax_coords.'+passenger_id+'.longitude'] : latitude,
        //         ['live_pax_coords.'+passenger_id+'.pax_update_time']: firebase.firestore.FieldValue.serverTimestamp(),
        //         ['live_pax_coords.'+passenger_id+'.pax_accuracy']: position.coords.accuracy,
    
                
        //     })
        // }else{
        //     console.log("no pax id yet in locsuccess " + passenger_id)

        // }
        
        console.log("location successfully fetched for " + passenger_id)

        setCookie('location_granted', (location_granted || Boolean(location_granted_cookie)), { path: '/' });

        //just in case pax turn off loc_services after initial auth :D
        dispatch({ type: 'Location_Error_Reset' })
        dispatch({ type: 'Location_Request_Granted' })
        dispatch({ type: 'Pax_Coords_Update', payload: {coords: [latitude, longitude]} })

        return [latitude, longitude]
    };

    function locError(err) {
        dispatch({ type: 'Location_Request_Declined' })
        dispatch({ type: 'Location_Error_Denied' })
        setCookie('location_granted', location_granted, { path: '/' });
        console.log("LIVE location Blocked initi" + location_granted)

        console.log("error!");
        console.log(err);

    }

    // START GEO Location verification of passenger
    function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
      }
      
      function deg2rad(deg) {
        return deg * (Math.PI/180)
      }
    


    // START QR_ID to Driver UID lookup
    async function getDriverUID(qr_id) {
        const cap_qr = qr_id.toUpperCase()
        try {
            console.log("trying getDriver again w/ ", cap_qr)
            const driverRef = db.collection('Lookup').doc(cap_qr);
            const id_snap = await driverRef.get();

            const tmp_uid = id_snap.data().uid
            if(tmp_uid == ""){
                alert("Notify your driver to finish setting up Strive")                 
            }else{
                setUID(tmp_uid)
                console.log("god uid w/ ", tmp_uid)

                setFetched(true)
                
                return tmp_uid
            }

        } catch (e) {
            alert("Couldn't find driver's ID - Notify your driver to finish setting up in Strive.FM App") 
            console.log(e) 
        }
    }

    async function getPassengerFingerprint() {
        try {
            const fpPromise = FingerprintJS.load({
                token: 'baxubysPJyzkkUyEEZUl'
            })
            const fp = await fpPromise
            const result = await fp.get()

            // This is the visitor identifier:
            const passenger_id = result.visitorId
            setPassengerID(passenger_id)

            return passenger_id;

        } catch (e) { console.log(e) }
    }

    function fetch_guest_name(){
        var live_count = Object.keys(live_pax).length
        return "guest" + live_count
    }

    useEffect(()=>{
        if(pax_access_cookie != "NO_ACCESS"){
            console.log("IN HERE ", pax_access_cookie);
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' , 'Authorization': "Bearer " + pax_access_cookie},
                // body: JSON.stringify({ title: 'React PUT Request Example' })
            };
            const profile = fetch('https://api.spotify.com/v1/me', requestOptions)
            profile.then((body) => {
                console.log(body)
                if(body && (body.status != 200 || !body.ok)){
                    if(body.status == 401){ //&& body.message && body.message.includes("expired")
                        console.log('token expired so cookies wiped err: ', body.message)
                        // removeCookie("access_token")
                        // removeCookie("refresh_token")
                        setCookie('pax_name', '', { path: '/' })
                        setCookie('pax_img', '', { path: '/' })
                        setCookie('access_token', '', { path: '/' })
                        setCookie('refresh_token', '', { path: '/' })

                        dispatch({type: 'Pax_Refresh', payload: {pax_refresh: ''}})
        
                    }else{
                        console.log('other profile status: ' + profile.status + " heres profile message " + profile.message)
                    }
                    
                }else if (body && (body.status == 200 || body.ok)){
                    const profile_ = body.json()
                    profile_.then((fin) => {
                        setPaxEmail(fin.email)
                        const first_name = fin.display_name.split(" ")
                        console.log('fin profile resp ' + fin + " with first name " + first_name);

                        setPaxImg(fin.images[0].url)
                        setPaxName(first_name[0])
                        setPaxURI(fin.uri)                        

                    })
                }else{
                    console.log("EXPIRED ACESSS TF");

                    setCookie('pax_name', '', { path: '/' })
                    setCookie('pax_img', '', { path: '/' })
                    setCookie('access_token', '', { path: '/' })
                    setCookie('refresh_token', '', { path: '/' })

                    dispatch({type: 'Pax_Refresh', payload: {pax_refresh: ''}})

                }
            })
        }else{
            console.log("NO ACESSS TF");
            setCookie('pax_name', '', { path: '/' })
            setCookie('pax_img', '', { path: '/' })
            setCookie('access_token', '', { path: '/' })
            setCookie('refresh_token', '', { path: '/' })

            dispatch({type: 'Pax_Refresh', payload: {pax_refresh: ''}})
        }

    }, [pax_access_cookie]);

    useEffect(() => {
        console.log("refresh token is now " + pax_redux_refresh)
    }, [pax_redux_refresh])

    useEffect(()=>{
        if(pax_queue != []){
            dispatch({type: 'Pax_Queue', payload: {pax_queue: pax_queue}})
        }
    }, [pax_queue])

    useEffect(()=>{
        var pax_id = ""
        if(passenger_id !=""){ 
            pax_id = passenger_id
        }else if (passenger_id_cookie && passenger_id_cookie != ""){
            pax_id = passenger_id_cookie
        }
        if(pax_img!=""){
            setCookie('pax_img', pax_img, { path: '/' });
            // dispatch({type: 'Pax_Refresh', payload: {pax_refresh: pax_access_cookie}})
            // console.log('pax_img', pax_img)
        }
        if(pax_name!=""){
            console.log("pax_name now " + pax_name)

            setCookie('pax_name', pax_name, { path: '/' });
            setCookie('guest_pax_name', '', { path: '/' });

            // dispatch redux update AFTER cookies set
            dispatch({type: 'Pax_Refresh', payload: {pax_refresh: pax_access_cookie}})

            // console.log('pax_name', pax_name)
        }
        else if (pax_guest_name != ""){

            var tmp_guest = pax_guest_name
            setPaxGuestName(tmp_guest)
            console.log("guest pax_name set instead to " + tmp_guest)

            setCookie('pax_name', '', { path: '/' });
            setCookie('guest_pax_name', tmp_guest, { path: '/' });

        }

        //STORE SPOTIFY_AUTH_DETAILS PER PAX DOC ALSO
        if(pax_access_cookie!="" && pax_refresh_cookie!="" && pax_id!=""){
            const check_pax = docExists("Passengers", pax_id)
            try{ 
                check_pax.then((check_val)=> {
                    if(check_val){
                        const unix_rn = Date.now() / 1000
                        console.log("attaching spotify auth to pax: " + pax_id)
                        db.collection('Passengers').doc(pax_id).update({
                            'spotify_access' : pax_access_cookie,
                            'spotify_refresh' : pax_refresh_cookie,
                            'spotify_name' : pax_name,
                        }).then((e)=>{
                            console.log("updated same PAX doc w/ spotify auth ", e)
                        })
                    }else{
                        console.log("STORING PAX REFRESH TOKEN w/o any pax.docs ")
                    }
                }) 
                
            }catch (e){
                console.log("STORING PAX REFRESH TOKEN per pax doc FAILED W/ exception ", e)
            }
        }
        

        if (pax_id != "" && pax_sesh_id != "" && pax_name!=""){
            const check_pax_sesh = deepDocExists("Passengers", pax_id, "sessions", pax_sesh_id)
            try{ 
                check_pax_sesh.then((check_val)=> {
                    if(check_val){
                        const unix_rn = Date.now() / 1000
                        console.log("attaching spotify auth to PAX_SESH doc: " + pax_id)
                        db.collection('Passengers').doc(pax_id).collection("sessions").doc(pax_sesh_id).update({
                            'potential_pax_id': pax_id,
                            'actions':firebase.firestore.FieldValue.arrayUnion('SPOTIFY_AUTH_SUCCESS'),
                            'interactions': firebase.firestore.FieldValue.arrayUnion({
                                'action' : "SPOTIFY_AUTH_SUCCESS",
                                'meta' : {
                                    'spotify_access' : pax_access_cookie,
                                    'spotify_refresh' : pax_refresh_cookie,
                                    'spotify_name' : pax_name,
                                    
                                },
                                'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                            }),
                        }).then((e)=>{
                            console.log("updated same PAX_SESH doc WITH SPOTIFY_AUTH_SUCESS", e)
                        })
                    }else{
                        console.log("STORING PAX SPOTIFY REFRESH TOKEN w/o any pax.sesh.docs ")
                    }
                }) 
                
            }catch (e){
                console.log("STORING PAX REFRESH TOKEN FAILED W/ exception ", e)
            }

        }else{
            console.log("UH OH sesh_id "+ pax_sesh_id + " pax_id "+ pax_id)
        }
        
    }, [pax_img, pax_name, pax_sesh_id]) //pax_name

    useEffect(()=>{
        if(driverTips!={} && Object.keys(driverTips).length > 0){
            dispatch({type: 'Driver_Tips_Update', payload: {driver_tips: driverTips}})

            console.log('driver_tips updated again', driverTips)
        }
        
    }, [driverTips])

    useEffect(()=>{
        console.log("in coords hook with coords: " + coords  + " and pax_id " + passenger_id + " loc granted " + location_granted)
        if(location_granted && passenger_id != "" && coords && coords.length > 0) { 
            const pax_lat = coords[0]
            const pax_long = coords[1]

            db.collection('Users').doc(uid).update({
                // ['currentSession.location.pax_latitude'] : latitude,
                // ['currentSession.location.pax_longitude']: longitude,
                // ['currentSession.location.pax_update_time']: firebase.firestore.FieldValue.serverTimestamp(),
                // ['currentSession.location.pax_accuracy']: position.coords.accuracy,
                ['live_pax_coords.'+passenger_id+'.latitude'] : pax_lat,
                ['live_pax_coords.'+passenger_id+'.longitude'] : pax_long,
                ['live_pax_coords.'+passenger_id+'.pax_update_time']: firebase.firestore.FieldValue.serverTimestamp(),
                // ['live_pax_coords.'+passenger_id+'.pax_accuracy']: position.coords.accuracy,
            })
        }else{
            console.log("no pax id yet in locsuccess " + passenger_id)
        }
    }, [passenger_id, coords, location_granted])

    //update unique_pax : [passenger_id]
    useEffect(()=>{
        if(passenger_id != "" && uid != "") { 
            db.collection('Users').doc(uid).update({
                'unique_pax' : firebase.firestore.FieldValue.arrayUnion(passenger_id),
                // ['live_pax_coords.'+passenger_id+'.pax_accuracy']: position.coords.accuracy,
            })
            console.log("pax id " + passenger_id + " updated for driver " + uid)
        }else{
            console.log("no pax id yet in locsuccess " + passenger_id)
        }
    }, [passenger_id, uid])

    //current playing hook
    useEffect(() => {
        if (trackName != prev_song) {
            // console.log('prev song', prev_song)
            // console.log('current song', trackName)
            dispatch({ type: "Song_Update", payload: { song: trackName } });
            dispatch({ type: "CDN_update", payload: { cdn: CDN } });
            dispatch({ type: "Artist_update", payload: { artist: artist_name } });
            dispatch({ type: "URL_update", payload: { url: track_url } });
            dispatch({ type: "Increment" });

        }
    }, [trackName])

    //reset on sesh ended hook
    useEffect(() => {
		//reset prev_song ready for next start of sesh
		if(!status){
			dispatch({ type: "Song_Update", payload: { song: "finished" } });
		}
        if(last_queued_song != ""){
            dispatch({ type: "Queued_Song_Update", payload: {last_queued_song: last_queued_song}})
        }
    }, [status, last_queued_song])

    useEffect(() => {
        if (uid != "") {
            db.collection('Users').doc(uid).onSnapshot(snapshot => {
                // const driver_pax_proximity = snapshot.data().currentSession.location.driverProximity
                // const pax_proximity = snapshot.data().currentSession.location.proximity

                // setDriverPaxProximity(driver_pax_proximity)
                // setPaxProximity(pax_proximity)

                setSnapshots(snapshots+1)
                setUserStatus(snapshot.data().isSessionActive);
                setName(snapshot.data().fullName);
                setStart(snapshot.data().lastCuratedOnUnix)
                setRefreshToken(snapshot.data().spotifyData.access_token)
                setOGIDX(snapshot.data().currentSession.og_idx)
                setQueueIDX(snapshot.data().currentSession.pax_queued_idx)
                
                const latt = snapshot.data().currentSession.location.latitude
                const longg = snapshot.data().currentSession.location.longitude
                const accuracy = snapshot.data().currentSession.location.accuracy
                setDriverLong(longg)
                setDriverLat(latt)
                setDriverAccuracy(accuracy)
                // const driver_pax_proximity = snapshot.data().config.enablePaxRemote
                console.log('states driver prox ' + driverPaxProximity + " , lat " + latt + " and pax_queue_control " + snapshot.data().config.enablePaxQueueing + " on snapshot " + snapshots)

                setDriverPaxQueue(snapshot.data().config.enablePaxQueueing ?? true)
                setDriverPaxVolume(snapshot.data().config.enablePaxVolControl ?? true)

                const curr_cdn = snapshot.data().currentSession.currentTrack.coverArt
                const curr_song = snapshot.data().currentSession.currentTrack.trackName;
                const artistName = snapshot.data().currentSession.currentTrack.artistName;
                const url = snapshot.data().currentSession.currentTrack.url+"?utm_campaign=com.app";
                                
                setAlias(snapshot.data().remoteAlias || "your driver")

                setLastQueued(snapshot.data().last_queued_song)

                setCDN(curr_cdn)
                setArtistName(artistName)
                setTrackURL(url)
                setTrackName(curr_song) // set song last so other curr_* states are updated before redux callback

                if(snapshot.data() && snapshot.data().live_pax_coords){
                    setLivePax(snapshot.data().live_pax_coords)
                    console.log("found num of live pax ", Object.keys(live_pax))
                    console.log("found num of live pax ", Object.keys(snapshot.data().live_pax_coords))
                    
                }else{
                    console.log("NO LIVE PAX COOREDS IN DB YET")
                }
                

                

                if(snapshot.data() && snapshot.data().tipHandle){
                    setDriverTips(snapshot.data().tipHandle)
                    // console.log("set queue from db")
                }else{
                    console.log("NO DRIVER TIPS SETUP IN DB YET")
                }

                if(snapshot.data() && snapshot.data().pax_queue){
                    setPaxQueue(snapshot.data().pax_queue)
                    // console.log("set queue from db")
                }else{
                    console.log("NO PAX QUEUE DOC IN DB YET")
                }
            })
        }else{
            getDriverUID(qr_id)
            console.log("got driver uid")
        }
        // 

        if (uid!= ""){
            let watcher = null
            var geo_interval = null
            console.log("before geo start")

            if (!navigator || !navigator.geolocation) {
                console.log("GG location not supported/requested")
                return
            } else {
                console.log("location good to go")

                // either use watcher or subscribe to firestore and fetch location on driver coord change by X mi.
                // watcher = navigator.geolocation.watchPosition(locSuccess, locError, geoSettings);

                // DO FIRST ONCE ON LOAD BEFORE TIMER SET
                const res = navigator.geolocation.getCurrentPosition(locSuccess, locError, geoSettings);
                console.log("FINISHED FIRST GELOC REPORT OF PAX")
                //send location to firestore every ~10 sec?
                geo_interval = setInterval(() => {
                    const res = navigator.geolocation.getCurrentPosition(locSuccess, locError, geoSettings);
                }
                , 60000);
        
            }

            // SYNC COOKIES only once per pax 
            if (passenger_id == "" && (passenger_id_cookie == "")){
                const tmp_pax_id = getPassengerFingerprint();
                if(guest_pax_name==""){
                    var tmp_guest = fetch_guest_name()
                    setCookie('guest_pax_name', tmp_guest, { path: '/' });
                }

                
                tmp_pax_id.then((pax_id)=>{
                    setCookie('passenger_id', pax_id, { path: '/' });
                    console.log("pax id cookie set ", passenger_id)
                    try {
                        // db.collection('Users').doc(uid).update({
                        //     all_pax : firebase.firestore.FieldValue.arrayUnion(pax_id),
                        //     passengerRequest: "none",
                        //     live_pax : firebase.firestore.FieldValue.arrayUnion(pax_id),

                        //     //if doc doesnt exist already, then reset pax queue?
                        // })
                        console.log("driver doc updated w/ pax", pax_id)
                        setPassengerID(pax_id) 

                    }catch (e){
                        console.log(e)
                    }
                })
            }else if (passenger_id_cookie && passenger_id_cookie != ""){
                setPassengerID(passenger_id_cookie) 
                // db.collection('Users').doc(uid).update({
                //     all_pax : firebase.firestore.FieldValue.arrayUnion(passenger_id_cookie),
                //     passengerRequest: "none",
                //     live_pax : firebase.firestore.FieldValue.arrayUnion(passenger_id_cookie),

                //     //if doc doesnt exist already, then reset pax queue?
                // })
                console.log("passenger_id set from cookie ", passenger_id)

            }

            var unix_rn = Date.now() / 1000
            if(session_id_cookie == ""){
                console.log("sesh COOKIE", session_id_cookie)
                unix_rn = Date.now() / 1000
                setCookie("session_id", unix_rn, {path : '/', expires: new Date(Date.now()+1200000)}) //for frequent refreshes to map to same session_id

            }else{
                console.log("CACHED sesh COOKIE", session_id_cookie)
                unix_rn = session_id_cookie
            }
            dispatch({ type: "session_id_update", payload: { session_id: unix_rn+""} });
            setPaxSeshID(unix_rn+"")
            
        }

        // return () => watcher && navigator.geolocation.clearWatch(watcher);
        return () => {
            if(geo_interval && geo_interval != null){
                console.log("geo interval cleared")
                clearInterval(geo_interval);
            }
          };

    }, [uidFetched]);

    const docExists = async (docName, docId) => (await db.collection(docName).doc(docId).get()).exists
    const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists

    // Per pax hook
    useEffect(()=>{
        if(passenger_id != "" && Object.keys(live_pax).length > 0){
            const pax_keys = Object.keys(live_pax)
            if(passenger_id in live_pax){ // can also use pax_keys.hasOwnProperty(passenger_id) for pax's FIRST geo push
                const pax_coord_data = live_pax[passenger_id]
                console.log("live pax updated and " + passenger_id + " found from pax proximity hook w/ live_pax " + JSON.stringify(live_pax, null, 2))

                if("proximity" in pax_coord_data){
                    console.log("Pax proximity FOUND N SET")

                    setPaxProximity(Boolean(pax_coord_data["proximity"])) // could be false if geofence blocked lat/long
                }else{
                    setPaxProximity(true) // INITIALLY set to true since ios takes 1-3 sec to add Proximity key and mitigate weird flashes on remote
                    // setPaxProximity(false) // could be false if geofence blocked lat/long
                    console.log("Pax proximity not even set cuz just scanned")
                }

                if("queue_delay" in pax_coord_data) {
                    console.log("FOUND QUEUEDELAY IN DB as, ", pax_coord_data["queue_delay"])
                    setCircleDelayDB(Boolean(pax_coord_data["queue_delay"]))
                }else{
                    setCircleDelayDB(false)
                }

                if("pax_preferences" in pax_coord_data) {
                    console.log("FOUND PAX_PREFS IN DB as, ", pax_coord_data["pax_preferences"])
                    dispatch({type: 'Pax_Prefs', payload: {preferences: pax_coord_data["pax_preferences"]}})

                }

                if("driver_secret" in pax_coord_data && !("expired" in pax_coord_data)){
                    // const secret_granted_time = new Date(pax_coord_data["secret_granted_time"]._seconds * 1000)
                    const secret_granted_time = (pax_coord_data["secret_granted_time"].toDate())

                    //TODO: Add driver secret timeout by comparing clientside time accessed VS time secret granted!!
                    console.log("pax secret assigned on date " + secret_granted_time)
                    setDriverPaxProximity(Boolean(pax_coord_data["driver_secret"]))

                }else if("expired" in pax_coord_data){ //outdated pax data after pax exits still in live_pax
                    console.log("pax " + passenger_id + " sadly expired driver secret ")
                    setDriverPaxProximity(false)
                }
                
                if(pax_guest_name == ""){
                    var tmp_guest = fetch_guest_name()
                    setPaxGuestName(tmp_guest)
                    console.log("guest pax_name set instead to " + tmp_guest)
        
                    setCookie('pax_name', '', { path: '/' });
                    setCookie('guest_pax_name', tmp_guest, { path: '/' });
                }
                
                //update genre distribution graph
                //if GENRES NOT INCORPORATED FROM THIS LIVE PAX_ID (set bool in live_pax_coords.pax_id.genres_incorporated (AND add queue_timer bool))
                const tmp_genre_dist = {"rap":0, "hip hop":0, "pop":0, "r&b":0, "rock":0, "edm":0, "indie":0, "classical":0, "country":0}
                for(const tmp_pax_id in live_pax){
                    const tmp_dat = live_pax[tmp_pax_id]
                    const tmp_genres = tmp_dat['pax_preferences']

                    for(const tmp_gen_idx in tmp_genres){
                        const tmp_gen = tmp_genres[tmp_gen_idx]
                        tmp_genre_dist[tmp_gen] += 1
                        // setGenreDist(tmp_genre_dist)
                        // setGenreDist(prevState => ({ ...prevState, tmp_gen: prevState[tmp_gen] + 1 }));
                    }
                    setGenreDist(tmp_genre_dist)
                }


            }else{
                console.log("ios geo manager REMOVED pax id " + passenger_id + " in live_pax")
                setPaxProximity(false) //always false since this id no longer exists since geo data wasnt sent in >5min
            }
        }else{
            console.log("NOT FINDING ANY live_pax since empty")
            setPaxProximity(false) //no pax sending data at all?

        }
    }, [live_pax, passenger_id])


    //PAX INITIAL DB LOG (later connect w/ spotify login)
    useEffect(()=>{
        var pax_id = ""
        if(passenger_id !=""){ 
            pax_id = passenger_id
        }else if (passenger_id_cookie && passenger_id_cookie != ""){
            pax_id = passenger_id_cookie
        }

        var loc_granted = location_granted || Boolean(location_granted_cookie)
        // dispatch({ type: 'Location_Error_Reset' })        
        // dispatch({ type: 'Location_Request_Granted' })
        
        if(pax_id && pax_id !="" && uid!="" && pax_sesh_id!="" && start!= -1){ //session_id is unix
            console.log("updating pax " + pax_id + " for session " + pax_sesh_id + "with driver_sesh " + start + "loc_granted " + location_granted + " & loc_grant_cookie " + location_granted_cookie)

            const check_pax = docExists("Passengers", pax_id)
            check_pax.then((check_val)=> {
                console.log("check_val in passengers doc " + check_val)
                if(check_val){
                    db.collection('Passengers').doc(pax_id).update({
                        'pax_id' : pax_id,
                        'strive_drivers' : firebase.firestore.FieldValue.arrayUnion(uid),
                        'sessions':  firebase.firestore.FieldValue.arrayUnion(pax_sesh_id),
                        'striver_sessions': firebase.firestore.FieldValue.arrayUnion(start),
                        'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
                    })
                }else{ //in between refreshes of same pax_sesh
                    db.collection('Passengers').doc(pax_id).set({
                        'pax_id' : pax_id,
                        'strive_drivers' : firebase.firestore.FieldValue.arrayUnion(uid),
                        'sessions':  firebase.firestore.FieldValue.arrayUnion(pax_sesh_id),
                        'striver_sessions': firebase.firestore.FieldValue.arrayUnion(start),
                        'last_started': Number(pax_sesh_id),
                        'last_used': firebase.firestore.FieldValue.serverTimestamp(),
    
                    }, {merge: true}).then((e)=>{
                        console.log("SET new PAX DOC ", e)
                    })
                }
                
            })

            // const pax_sesh_doc_ref = db.collection('Passengers').doc(pax_id).collection("sessions").doc(pax_sesh_id).get()
            const check_pax_sesh = deepDocExists("Passengers", pax_id, "sessions", pax_sesh_id)
            try{ 
                check_pax_sesh.then((check_val)=> {
                    if(check_val){
                        var action = (paxProximity || driverPaxProximity) ? 'refreshed_queue_view' : "START_geofence_locked"

                        const unix_rn = Date.now() / 1000

                        console.log("updating w/ refreshedqueueview sesh: ", pax_sesh_id)
                        db.collection('Passengers').doc(pax_id).collection("sessions").doc(pax_sesh_id).update({
                            'potential_pax_id': pax_id,
                            'actions':firebase.firestore.FieldValue.arrayUnion(action),
                            'interactions': firebase.firestore.FieldValue.arrayUnion({
                                'action' : action,
                                'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                            }),
                            'driver_uid': uid,
                            'striver_session_id': start,
                            'geo_accepted': loc_granted
                        }).then((e)=>{
                            console.log("updated same PAX_SESH doc ", e)
                        })
                    }else{
                        console.log("FRESH RESET OF INTERACTIONS ", pax_sesh_id )
                        var action = (paxProximity || driverPaxProximity) ? 'START_PAX_SESSION' : "START_geofence_locked"

                        db.collection('Passengers').doc(pax_id).collection("sessions").doc(pax_sesh_id).set({
                            'potential_pax_id': pax_id,
                            'time_accessed': firebase.firestore.FieldValue.serverTimestamp(),
                            'actions': [action],
                            'interactions': [
                                {
                                    'action' : action,
                                    'time' : Number(pax_sesh_id) //firebase.firestore.FieldValue.serverTimestamp(),
                                }
                            ],
                            'session_id': Number(pax_sesh_id), 
                            'striver_session_id': start,
                            'driver_uid': uid,
                            'geo_accepted': loc_granted
                        }, {merge: true}).then((e)=>{
                            console.log("SET new PAX_SESH DOC ", e)
                        })
                    }
                }) 
                
            }catch (e){
                console.log(e)
            }

        }

        // return ()=>{
        //     if(pax_id != "" && pax_sesh_id != ""){
        //         const unix_rn = Date.now() / 1000
		// 		console.log("return leaving away from lookup PAX SESH END")

        //         const pax_sesh_doc_ref = db.collection('Passengers').doc(pax_id).collection("sessions").doc(pax_sesh_id)
        //         pax_sesh_doc_ref.set({
        //             'interactions': firebase.firestore.FieldValue.arrayUnion({
        //                 'action' : 'PAX_SESSION_ENDED',
        //                 'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
        //             }),
        //         }, {merge: true})
        
        //     }
        // }
        
    }, [location_granted, uid, passenger_id, pax_sesh_id, start])

    //CALLBACK TO UPDATE THE TRACKS THAT PAX IS ACTIVELY EXPOSED ON QR FOR IN STRIVER.SESH.TRACKS
    useEffect(()=>{
        var pax_id = ""
        if(passenger_id !=""){ 
            pax_id = passenger_id
        }else if (passenger_id_cookie && passenger_id_cookie != ""){
            pax_id = passenger_id_cookie
        }


        if(track_url!="" && pax_id && pax_id !="" && uid!="" && pax_sesh_id!="" && start!= - 1 && og_idx!=-1 && pax_queued_idx!=-1){ //session_id is unix
            console.log("updating pax " + pax_id + " for session " + pax_sesh_id + "with driver_sesh " + start + "on og_idx " + og_idx + " & pax queued idx " + pax_queued_idx)
            var curr_track_parts = track_url.split("/") 
            var curr_track_id_utm = curr_track_parts[curr_track_parts.length - 1]
            var curr_track_id_parts = curr_track_id_utm.split("?")
            var curr_track_id = curr_track_id_parts[0]
            
            var curr_track_doc_ = curr_track_id+"_"+ ( og_idx - pax_queued_idx)
            //STILL USING track_OGIDX INSTEAD OF track_REPEATNUM to update session.tracks
            //TODO: store currentSession.currentTrack.campaign_id and if campaign_id== "BOT" && genres[0] == "pax_perso" then update pax_tracks since pax queued
            db.collection('Sessions').doc(uid).collection('sessionData').doc(String(start)).collection("tracks").doc(curr_track_doc_).update({
                'pax_active' : firebase.firestore.FieldValue.arrayUnion({
                    'pax_id': passenger_id,
                    'session_id' : pax_sesh_id,
                }),
            })
        }
    }, [passenger_id, uid, pax_sesh_id, start, track_url, og_idx, pax_queued_idx])


    useEffect(()=>{
        console.log("prox changed to "+ paxProximity + " and driverpaxprox to " +driverPaxProximity)
    }, [paxProximity, driverPaxProximity])

    if(status && (paxProximity || driverPaxProximity)){ 
        return (
            <>
                <Navbar uid={uid} qr_id={qr_id} active={status} driver_name={name} passenger={passenger_id} driver_pax_proximity={driverPaxProximity} pax_proximity={paxProximity} pax_access={pax_access_cookie} pax_refresh={pax_refresh_cookie} driver_access={refreshToken} start={start} idx={og_idx} queue_control={driverPaxQueue} vol_control={driverPaxVolume} pax_sesh_id={pax_sesh_id} genre_dist={genre_dist} circle_delay={circle_delay} queued_idx={pax_queued_idx}/>
                {/* <RemoteTabs qr_id={qr_id} uid={uid} active={status} passenger={passenger_id} driver_pax_proximity={driverPaxProximity} pax_proximity={paxProximity} pax_access={pax_access_cookie} driver_access={refreshToken} start={start} idx={og_idx}/> */}
                <Remote uid={uid} passenger={passenger_id} refresh={refreshToken} vol_control={driverPaxVolume}/>

            </>
    )
    }else if (status && !paxProximity && !driverPaxProximity){
        return (
            <>
                <RideEnded uid={uid} alias={alias} active={status} driver_name={name} passenger={passenger_id} pax_proximity={paxProximity} driver_pax_proximity={driverPaxProximity} pax_access={pax_access_cookie} driver_access={refreshToken} start={start} idx={og_idx}/>
            </>
        );
    }else{
        return (
        <div style={{backgroundColor: "#000000"}}>
            <Inactive alias={alias} uid={uid} active={status} driver_name={name} passenger={passenger_id} start={start} idx={og_idx}/>
        </div>
        );
    }
}

export default Lookup;