import React, { useState, useEffect, useRef } from 'react';
import Remote from './Remote.js';
import RemoteTabs from './Tab.js'

import Lookup from './Lookup.js'
import LocationInactive from './LocationInactive.js'
import GeolocationDenied from './GeolocationDenied.js'
import DefaultLookup from './DefaultLookup.js'
import db from './firebase.config.js';

import '@fortawesome/fontawesome-free/css/all.min.css';

import './main.css'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useCookies, withCookies} from 'react-cookie';

const useUnload = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);

};

function App(props) {
  const access_granted = false
  const location_granted = useSelector(state=> state.location_granted)
  const location_error = useSelector(state=> state.error)
  const [cookies, setCookie] = useCookies(['user']);

  const location_granted_cookie = cookies.location_granted
 
  useUnload(e => {
    e.preventDefault();
    const exit = window.confirm('Are you sure you want to leave?');
    if (exit) window.close();
  });

  
  if(location_error == "NONE"){
    return (location_granted || Boolean(location_granted_cookie)) ? (
      <Router>
        <>
        <Switch>
        < Route exact path="/" component={DefaultLookup} />
        < Route path="/:qr_id" component={Lookup} />
        </Switch>
        </>
      </Router>

    ) : 
    (
      <div>
        <LocationInactive />
      </div>
    );
    }else{
      return  (
        <GeolocationDenied />
      )
    }
}

export default withCookies(App);
