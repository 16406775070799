// import * as React from "react";
import React, { useState, useEffect } from 'react';

import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress style={{'color': '#6C72FF'}} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#6C72FF",
        }}
      >
        <Typography variant="button" component="div" color="black" className="timer_text">
          {`${Math.round(props.value) / -20}s`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired
};

export default function CircleTimer(props) {
  const min_sec = props.sec
  const [sec, setSec] = useState(min_sec);

  useEffect(() => {
    var timer_name = "countdown"
    // if(min_sec < 15) {
    //   timer_name = "skip_countdown"
    // }
    const timer = setInterval(() => {
      setSec((tmp_sec) => {
        // const new_sec = (tmp_sec >= 1 ? tmp_sec - 1 : min_sec)
        if(tmp_sec > 0){
          const new_sec = tmp_sec - 1
          localStorage.setItem(timer_name, new_sec);
          return new_sec;
        }
        else{
          const new_sec = min_sec
          localStorage.removeItem(timer_name);
          return new_sec;

        }
        
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      // localStorage.removeItem(timer_name);
    };
  }, [min_sec]);

  return <CircularProgressWithLabel value={sec * -20} />;
}