import React, { useState, useEffect, Component } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import Checkbox from './Checkbox.js'
import Track from './Track.js';
import Popup from 'reactjs-popup';

import spotify_logo from './spotify.svg'


// import TouchRipple from '@mui/core/ButtonBase/TouchRipple';
import CloseIcon from '@mui/icons-material/Close';

import {
  Paper,
  IconButton,
  InputBase,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Modal,
  Drawer,
  SwipeableDrawer,
  Snackbar,
  Box,
  Typography,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import AttachMoney from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

import db from './firebase.config.js';
import firebase from 'firebase/app';
import "firebase/firestore";
import './main.css'

import { useCookies } from 'react-cookie';
import DragTable from './DragTable.js';
import CircleTimer from './CircleTimer.js';

import { Chart } from 'chart.js';
import Toast from './Toast.js';

import GraphModal from './GraphModal.js';
import { useTheme } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSwipeable } from 'react-swipeable';


const SwipeableHelpDialog = (props) => {
  const { open, onClose, vol_control, anchor_el } = props;
  const theme = useTheme();
  const [pageIndex, setPageIndex] = useState(0);
  const maxPageIndex = 2;

  const handlePrevPage = () => {
    setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextPage = () => {
    setPageIndex((prevIndex) => Math.min(prevIndex + 1, maxPageIndex));
  };

  const handleSwipe = (delta_x) => {
    if (delta_x < 0 && pageIndex < maxPageIndex) {
      setPageIndex(pageIndex + 1);
    } else if (delta_x > 0 && pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const getAnchor = () => {
    if (pageIndex === 0) {
      return {
        vertical: "center",
        horizontal: "center"
      };
    } else if (pageIndex === 1) {
      return{
        vertical: "center",
        horizontal: "center"
      };
    } else if(pageIndex == 2){
      return {
        vertical: "center",
        horizontal: "center"
      }
    }
    return {
      vertical: "center",
      horizontal: "center"
    };
  };

  const getTransformAnchor = () => {
    if (pageIndex === 0) {
      return {
        vertical: "top",
        horizontal: "center"
      };
    } else if (pageIndex === 1) {
      return{
        vertical: "top",
        horizontal: "center"
      };
    } else if(pageIndex == 2){
      return {
        vertical: "top",
        horizontal: "center"
      }
    }
    return {
      vertical: "top",
      horizontal: "center"
    };
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe(-1)
      console.log('Swiped left!')
    },
    onSwipedRight: () => {
      handleSwipe(-1)
      console.log('Swiped right!')
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const remote_type = 'rideshare'

  return (
    <Modal
        open={open}
        onClose={onClose}
        // style={{ zIndex: 9999 }} // set higher z-index than other content
      >
    <Popover anchorEl={anchor_el} anchorOrigin={getAnchor()} transformOrigin={getTransformAnchor()} elevation={8} open={open} onClose={onClose} {...swipeHandlers}>
      {/* <SwipeableDrawer anchor={getAnchor()} open={open} onClose={onClose}> */}
    {/* <div {...swipeHandlers}> */}
          <Box className="dialog-container">
            <Box className="dialog-content">
              <Box className="dialog-title">
                {pageIndex === 0 && (
                  <Typography variant="h6">Take Control of the music!</Typography>
                )}
                {pageIndex === 1 && (
                  <Typography variant="h6">Song Selection</Typography>
                )}
                {pageIndex === 2 && (
                  <Typography variant="h6">Connecting to Spotify:</Typography>
                )}
              </Box>
              <Box className="dialog-body">
                {pageIndex === 0 && (
                  <Typography variant="body1">
                    • Queue, skip, {remote_type == 'rideshare' ? 'pause, ' : ''}and influence the genre of music<br />
                    • Set 'auto-skip' to skip songs hands-free every 35sec-3min {vol_control ? '& toggle volume' : ''} <br />
                    • Connect with Spotify for the full experience!<br />
                  </Typography>
                )}
                {pageIndex === 1 && (
                  <Typography variant="body1">
                    {/* • Search & Queue ANY Spotify track!<br /> */}
                    • After queueing a song, a 60second cooldown period gives others a chance. <br />
                    • Upcoming songs will change based on EVERYONE's favorite genres <br />
                    • In between songs, you may hear new music from our artists {':)'} <br />

                  </Typography>
                )}
                {pageIndex === 2 && (
                  <Typography variant="body1">
                    • fully tailors the Search and Queue-ing of songs for you!<br />
                    • improves upcoming song recommendations<br />
                    • (soon) allows you to save songs & generate playlists to your Library <br />
                    {/* (if you'd like to explore new artists music while earning $5+ daily, join our new reimagined feedback-friendly radio ux @ Strive.FM) — collect votes/emails for interested */}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className="dialog-footer">
              <Box className="pagination">
                {[0, 1, 2].map((index) => (
                  <Box
                    key={index}
                    className="pagination-item"
                    onClick={() => setPageIndex(index)}
                    style={{
                      backgroundColor:
                        index === pageIndex ? "#6C72FF" : "#C4C4C4",
                      cursor: "pointer"
                    }}
                  />
                ))}
              </Box>
              <Box className="controls">
                <IconButton
                  onClick={handlePrevPage}
                  disabled={pageIndex === 0}
                  sx={{ mr: 1 }}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  onClick={handleNextPage}
                  disabled={pageIndex === maxPageIndex}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </Box>
          </Box>
        {/* </div> */}
      </Popover>
      </Modal>

  );
};

class ResultsList extends Component {
    render() {
      const { results, focus } = this.props;
      return (
        <ul className="add-to-queue__search-results">
          <style jsx>{`
            .add-to-queue__search-results {
              list-style: none;
              margin: 0;
              padding: 0;
            }
            .add-to-queue__search-results-item {
              padding: 5px 0 5px 5px;
            }
            .add-to-queue__search-results-item--focused {
              background-color: #eee;
            }
            .container{
              display: flex;
            }
            .album-img{
                width: 64;
                padding-right: 1em;
            }
            .flex-item{
                flex-grow: 1;
            }
            .song-name {
              font-size: 1.3em;
              margin-bottom: 0.3em;
            }
          `}</style>
          {results.map((r, index) => {
            const isFocused = focus === index;
            const className =
              'add-to-queue__search-results-item' + (isFocused ? ' add-to-queue__search-results-item--focused' : '');
              return (
              <li key={r.id} className={className} onClick={() => this.props.onSelect(r)}>
                <div className="container">
                  <div className="album-img">
                    <img src={r.album.images && r.album.images.length > 2 ? r.album.images[2].url : spotify_logo}/>
                  </div>
                  <div className="flex-item">
                    <div className="song-name">{r.name}</div>
                    <div>{r.artists[0].name}</div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
}

class QueueList extends Component {
    render() {
      const { results, focus } = this.props;
      return (
        <ul className="add-to-queue__search-results">
          <style jsx>{`
            .add-to-queue__search-results {
              list-style: none;
              margin: 0;
              padding: 0;
              max-height: 21vh;
              overflow-y: scroll;

            }
            .actual-queue-item {
              padding: 5px 0 5px 5px;
            }
            .flex-item{
                flex-grow: 1;
            }
          `}</style>
          {results && results.map((track, index) => {
            const r=track['track_id']
            const pax_id=track['pax_id']
            const isFocused = focus === index;
            const className = 'actual-queue-item'
            return (
                // onClick={() => this.props.onSelect(r)}
              <li key={r}> 
                  {/* <div className="album-img">
                    <img src={r.album.images[2].url}/>
                  </div> */}
                  <div className="flex-item">
                    {/* <div className="song-name">{r.name}</div> */}
                    {/* <div className="song-name">{r}</div> */}
                    {/* <div>{pax_id}</div> */}
                      <div>
                        <Track
                          track_id={track.track_id}
                          artist={track.artist}
                          song_name={track.track_name}
                          url={track.url}
                          cover_art={track.cover_art} 
                          driver={track.driver_uid}
                          passenger={pax_id} 
                          index={index}
                          genres={["Fan Picked"]}
                          meta={track.meta ? track.meta : {}}
                          />
                      </div>
                  </div>
              </li>
            );
          })}
        </ul>
      );
    }
}

const BootstrapButton = withStyles({
    root: {
        boxShadow: 2,
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        // border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#6C72FF',
        fontFamily: [
            "San Francisco", 
            'Helvetica', 
            'Arial',
            '-apple-system',
        ].join(','),
        '&:hover': {
            // backgroundColor: '#9EA2FF',
            // borderColor: '#8F94FF',
            boxShadow: 2,
        },
    },
})(Button);


export default function Genres(props){
  const uid = props.uid
  const start = props.start
  const pax = props.passenger
  const queue_control = props.queue_control
  const vol_control = props.vol_control
  const dispatch = useDispatch();

  const idx = props.idx
  const queued_idx = props.queued_idx
  // console.log('genres fetch of pax id at all   ? ', pax)
  const pax_prefs = useSelector(state=>state.preferences)
  const pax_prefs_change = useSelector(state=>state.preferences_change)
  const redux_pax_queue = useSelector(state=>state.pax_queue)
  const last_queued_song = useSelector(state=>state.last_queued_song)

  const genre_dist = props.genre_dist

  const db_circ_delay = props.circle_delay

  const sesh_id = useSelector(state => state.session_id)

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anchor_el, setOpenTutEl] = useState(null);
  const [open_tut, setOpenTut] = useState(false);
  const [open_toast, setOpen] = useState(false);
  const [open_same_song_toast, setSameSongOpen] = useState(false);
  const [genres_changed_toast, setGenresChanged] = useState(false);

  const handleClick = (event) => {
    setOpenTutEl(event.currentTarget)
    setOpenTut(true);
    console.log("setting open_  to ", open_toast)
  }

  const [request_sent, set_request_sent] = useState("none")
  const [queue, setQueue] = useState({})
  const [flat_queue, setFlatQueue] = useState([])
  const [results, setResults] = useState([])
  const [text, setText] = useState("")
  const [focus, setFocus] = useState(-1)
  const [circle_delay, setCircleDelay] = useState((db_circ_delay || localStorage.getItem("countdown") != null) ? true : false)
  const [resume_delay, setResumeDelay] = useState(localStorage.getItem("countdown") != null ? (Number(localStorage.getItem("countdown"))) : 60)

  const [cookies, setCookie] = useCookies(['user']);

  const driver_access = props.driver_access
  const pax_access = props.pax_access

  const docExists = async (docName, docId) => (await db.collection(docName).doc(docId).get()).exists
  const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists

  const update_driver_queue = (track, pax_id) => {
    console.log('outside updating pax_queue for ' + uid + ' with track ' + track.track_id + " with pax_doc " + pax_tracks_doc + " with sesh start " + start)

    if(uid!="" && pax_id!="" && start!=-1 && idx!=-1){
      const queued_at = firebase.firestore.Timestamp.now()
      const queued_at_1 = firebase.firestore.Timestamp.now();
      const queued_at_2 = firebase.firestore.FieldValue.serverTimestamp();
      track['queued_at'] = queued_at
      var pax_key = 'pax_queue.'+pax_id
      db.collection('Users').doc(uid).update({
          [pax_key] : firebase.firestore.FieldValue.arrayUnion(track)
        })

      var tracks_doc = "pax_"+track.track_id
      var pax_tracks_doc = pax_id+"_"+track.track_id
      var tmp_tr_name = track.track_id + "_" + (queued_idx)
      var pax_idx = idx+0.001
      console.log('updating pax_queue for ' + uid + ' with track ' + track.track_id + " with pax_doc " + pax_tracks_doc + " with sesh start " + start)

      //update driver's corresponding session.uid.tracks
      db.collection('Sessions').doc(uid).collection('sessionData').doc(String(start)).collection('pax_tracks').doc(String(tmp_tr_name)).set(track, {merge: true})

      //update driver's corresponding session.uid.pax_tracks
      // db.collection('Sessions').doc(uid).collection('sessionData').doc(String(start)).collection('pax_tracks').doc(track.track_id).update(track)
      //TODO: Squeeze in pax_queue track into ACTUAL driver QUEUE so it shows in remote's queue view and history (fractionalize og_idx between [curr_idx.0001-curr_idx.9999])
    }else{
      console.log('driver doc not found ' + uid)
    }
    
  }

  //update passenger doc with interactions
  const update_pax_interactions = (track, pax) => {
    const check_pax_sesh = deepDocExists("Passengers", pax, "sessions", sesh_id)
    console.log("DOC EXIST CHECK ", check_pax_sesh)
    try{ 
      check_pax_sesh.then((check_val)=> {
          if(check_val){
              const unix_rn = Date.now() / 1000
              console.log("adding queue interaction: ", sesh_id)
              db.collection('Passengers').doc(pax).update({
                'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
              })

              db.collection('Passengers').doc(pax).collection("sessions").doc(sesh_id).update({
                  'actions':firebase.firestore.FieldValue.arrayUnion('queued_track'),
                  'interactions': firebase.firestore.FieldValue.arrayUnion({
                      'action' : 'queued_track',
                      'meta' : track,
                      'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                  }),
              }).then((e)=>{
                  console.log("finished updating same pax doc ", e)
              })
          }
      })
    }catch (e) {
      console.log("failed adding queue interaction ")
    }
  }
  const handleChange = e => {
      const text = e.target.value;
      setText(text)
      // this.setState({ text: text });
      if (text !== '') {
          // this.props.searchTracks(text);
          searchTracks(text)
      } else {
          setFocus(-1)
          setResults([])
          // this.setState({ focus: -1 });
          // this.props.searchTracksReset();
      }
  };

  const handleSelectElement = track => {
    if(queue_control) {
      // this.setText({ text: '' });
      if(last_queued_song == "" || (last_queued_song!="" && track.id != last_queued_song)){
        console.log('handleselect track ', track)
        setText("");
        // this.props.queueTrack(id);
        
        // index={index}, track_id={track_id}, artist={artist}, song_name={song_name}, url={url}, cover_art={cover_art} />
        var temp_url = "https://open.spotify.com/track/" + track.id + "?utm_campaign=com.app";
        var track_obj = {
          'track_id': track.id, 
          'track_name': track.name, 
          'artist': track.artists[0].name, 
          'cover_art' : track.album.images[2].url,
          'url' : temp_url, 
          'pax_id':pax,
          'driver_uid':uid,
          }
        // setQueue(queue => [...queue,  track_obj])

        update_driver_queue(track_obj, pax)
        update_pax_interactions(track_obj, pax)
        setCircleDelay(true)
        db.collection('Users').doc(uid).update({
          ['live_pax_coords.'+pax+'.queue_delay'] : true,
          'last_queued_song' : track.id,
        })
        setTimeout(() => {
          setCircleDelay(false)
          db.collection('Users').doc(uid).update({
            ['live_pax_coords.'+pax+'.queue_delay'] : false,
            'last_queued_song' : track.id,
          })
        }, 60000);
        // this.props.searchTracksReset();
        setResults([])
      }else{
        console.log("DONT QUEUE SAME back2back")
        setText("");
        setResults([])
        setSameSongOpen(true)
      }
    }else{
      console.log("SORRY DRIVER DiSABLED queueing. tell them to enable it from Strive.FM App settings")
      setText("");
      setResults([])
      setOpen(true)

    }
  };

  const handleBlur = e => {
  // todo: this happens before the item from the list is selected, hiding the
  // list of results. We need to do this in a different way.
  /*    this.setState({ focus: -1 });
  this.props.searchTracksReset(); */
  };

  const handleFocus = e => {
      if (e.target.value !== '') {
          searchTracks(e.target.value)
          // this.props.searchTracks(e.target.value);
      }
  };

  const handleKeyDown = e => {
      switch (e.keyCode) {
          case 38: // up
              // this.setState({ focus: this.state.focus - 1 });
              setFocus(focus - 1)
          break;
          case 40: // down
              // this.setState({ focus: this.state.focus + 1 });
              setFocus(focus + 1)
          break;
          case 13: {
            if(queue_control){
              let correct = false;
              if (focus !== -1) {
                if(results[focus].id != last_queued_song){
                  console.log('makes sense text ', text)

                  // this.props.queueTrack(this.props.search.results[this.state.focus].id);
                  // console.log(results[focus])
                  // setQueue(queue => [...queue, {'track_id': results[focus].name, 'pax_id':pax}])
                  var temp_url = "https://open.spotify.com/track/" + results[focus].id + "?utm_campaign=com.app";
                  var track_obj = {
                    'track_id': results[focus].id, 
                    'track_name': results[focus].name, 
                    'artist': results[focus].artists[0].name, 
                    'cover_art' : results[focus].album.images[2].url,
                    'url' : temp_url, 
                    'pax_id':pax,
                    'driver_uid':uid,
                    }
                  // setQueue(queue => [...queue,  track_obj])

                  update_driver_queue(track_obj, pax)
                  setCircleDelay(true)
                  db.collection('Users').doc(uid).update({
                    ['live_pax_coords.'+pax+'.queue_delay'] : true,
                    'last_queued_song' : results[focus].id, 

                  })
                  setTimeout(() => {
                    setCircleDelay(false)
                    db.collection('Users').doc(uid).update({
                      ['live_pax_coords.'+pax+'.queue_delay'] : false,
                      'last_queued_song' : results[focus].id, 

                    })
                  }, 60000);
                  correct = true;
                }else{
                  setSameSongOpen(true)
                  console.log("DONT QUEUE SAME back2back from focus searchresult")
                }
              }else {
                const text = e.target.value.trim();
                console.log('tf text ', text)
                if (text.length !== 0) {
                    // this.props.queueTrack(text);
                    console.log('text one ' + results[focus])
                    // setQueue(queue => [...queue, {'track_id': text, 'pax_id':pax}])
                    var temp_url = "https://open.spotify.com/track/" + results[focus].id + "?utm_campaign=com.app";
                    var track_obj = {
                      'track_id': results[focus].id, 
                      'track_name': results[focus].name, 
                      'artist': results[focus].artists[0].name, 
                      'cover_art' : results[focus].album.images[2].url,
                      'url' : temp_url, 
                      'pax_id':pax,
                      'driver_uid':uid,
                      }
                    // setQueue(queue => [...queue,  track_obj])
                    correct = true;
                }
                if (correct) {
                    setText("")
                    setResults([])
                    setFocus(-1)
                    // this.setState({ text: '' });
                    // this.props.searchTracksReset();
                    // this.setState({ focus: -1 });
                }
                break;
              }
            }else{
              console.log("SORRY DRIVER DiSABLED queueing. tell them to enable it from Strive.FM App settings")
              setText("");
              setResults([])

              setOpen(true)
        
            }
          }
      }
  };
  
  const SPOTIFY_API_BASE = 'https://api.spotify.com/v1';
  
  const searchTracks = (query) => {
      let shouldAddWildcard = false;
      if (query.length > 1) {
        const words = query.split(' ');
        const lastWord = words[words.length - 1];
        if (/^[a-z0-9\s]+$/i.test(lastWord) && query.lastIndexOf('*') !== query.length - 1) {
          shouldAddWildcard = true;
        }
      }
      var wildcardQuery = query + ""
      if(shouldAddWildcard){
          wildcardQuery = query + "*"
      }
      var auth_str = "Bearer " + pax_access
      if(pax_access=="NO_ACCESS"){
          auth_str = "Bearer " + driver_access
      }
      // use pax refresh if cookies on/auth flow all there in cookies; ELSE, use driver token to support queuing
      const requestOptions = {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
              'Authorization': auth_str,
          },
          // body: JSON.stringify({ title: 'React PUT Request Example' })
      };
      const query_url = SPOTIFY_API_BASE+"/search?q="+wildcardQuery+"&type=track&limit=10"
      console.log(auth_str)
      return fetch(query_url, requestOptions)
        .then(res => res.json())
        .then(res => {
          setResults(res.tracks.items);
        });
    };

  function sendPreferences(){
      db.collection('Users').doc(uid).update({
          passengerPreferences: pax_prefs,
          passengerRequest: "sent",
      }, {merge: true})
      console.log('request_sent')

      set_request_sent("sent");

  }

  useEffect(()=>{
    console.log("actually changed pax_prefs caught in genres " + pax_prefs_change)
    if(pax_prefs_change){
      setGenresChanged(true)
      setTimeout(()=>{
        setGenresChanged(false)
        dispatch({type: 'Pax_Prefs_Changed', payload: {preferences_change: false}})
      }, 2000)
    }
    
  }, [pax_prefs_change])

  useEffect(()=>{
    // queue.map((obj, index) => {
    //   const r=obj['track_id']
    //   const pax_id=obj['pax_id'] 
    //   console.log('queue now also has ', r);
    // })
    if(queue != {}){
      // console.log("flateening redux queue: ", queue)
      var flattened = []
      for (const [tmp_pax, tmp_queue] of Object.entries(queue)){
        flattened = flattened.concat(tmp_queue)
      }
      flattened.sort((a,b) => a.queued_at - b.queued_at);

      setFlatQueue(flat_queue => [...flattened])
      
    }

  }, [queue])

  useEffect(()=>{
    // queue.map((obj, index) => {
    //   const r=obj['track_id']
    //   const pax_id=obj['pax_id'] 
    //   console.log('queue now also has ', r);
    // })
    if(redux_pax_queue != []){
      // console.log("already existing queue ", redux_pax_queue)
      // setQueue(queue => [...redux_pax_queue, ...queue])
      // setQueue(queue => [...redux_pax_queue])
      setQueue(redux_pax_queue)
    }
    


  }, [redux_pax_queue])

  useEffect(() => {
      if(uid!=""){
          db.collection('Users').doc(uid).onSnapshot(snapshot => { //TODO: REMOVE SNAPSHOT (replace w/ redux stor var)
              set_request_sent(String(snapshot.data().passengerRequest));
          })
      }else{}
      console.log(request_sent)
  }, [uid])

  //DEBUGGIN HOOKS lol
  // useEffect(() => {
  //     console.log("OPEN_TOAST in genres ", open_toast)
  // }, [open_toast])
//   useEffect(() => {
//     console.log('circle delay set to ' + circle_delay)
//     if(!circle_delay && localStorage.getItem("countdown") == null){
//       console.log("circle_delay reset to false so deleting countdown")
//       localStorage.removeItem("countdown");
//     }
// }, [circle_delay])

  //resume circle Timer if refreshed get fucked
  useEffect(() => {
    if (localStorage.getItem("countdown") !== null){
      const resume_time = localStorage.getItem("countdown")
      console.log("COUNTDOWN FOUND" + resume_time)

      setCircleDelay(true)
      setResumeDelay(Number(resume_time))
      setTimeout(() => setCircleDelay(false), (1000 * Number(resume_time)));

    }
  
}, [])

useEffect(()=>{
  if (db_circ_delay && resume_delay == 60){ //so localstorage didn't update cuz nothing found
    setCircleDelay(true)
      console.log("COUNTDOWN DEFAULT TO 60 cuz dbcirc" + db_circ_delay)

      setResumeDelay(Number(60))
      setTimeout(() => {
        setCircleDelay(false)
        db.collection('Users').doc(uid).update({
          ['live_pax_coords.'+pax+'.queue_delay'] : false,
        })
      }, 60000);

  }
}, [db_circ_delay])

const handle_disabled_queue_close = (event, reason) => {
  console.log('in disabled close ' + reason )
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

const handle_same_song_close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSameSongOpen(false);
  };

const handle_genres_changed_close = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setGenresChanged(false);
};
  
  const handleTutClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenTut(false);
  };
  
  const disabled_queue_action = (
    <>
      <Button color="secondary" size="small" onClick={handle_disabled_queue_close}>
        CLOSE
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handle_disabled_queue_close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const same_song_action = (
    <>
      <Button color="secondary" size="small" onClick={handle_same_song_close}>
        CLOSE
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handle_same_song_close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const genres_changed_action = (
    <>
      <Button color="secondary" size="small" onClick={handle_genres_changed_close}>
        CLOSE
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handle_genres_changed_close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );


  return (
    <div className='genre_div'>
          <Snackbar
              open={open_toast}
              autoHideDuration={2000}
              onClose={handle_disabled_queue_close}
              message="Ask Striver to re-enable Queueing in Strive settings."
              action={disabled_queue_action}
              className="toast"
          />
          <Snackbar
              open={open_same_song_toast}
              autoHideDuration={10000}
              onClose={handle_same_song_close}
              message="Please don't queue the same song back-to-back!"
              action={same_song_action}
              className="toast"
          />
          <Snackbar
              open={genres_changed_toast}
              autoHideDuration={10000}
              onClose={handle_same_song_close}
              message="Upcoming songs will incorporate your fav genres (+ others' fav genres shown in graph...coming soon)!"
              action={genres_changed_action}
              className="toast"
          />
        <div className='genre_container'>
            {(genre_dist && Object.keys(genre_dist).length > 0) ? <GraphModal votes={Object.values(genre_dist)} /> : null} 
            <Checkbox uid={uid} pax={pax} sesh_id={sesh_id}/>
        </div>
        <div className='request_song'>
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>

            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            {
            // queue_control ? (

              circle_delay ? 
                <CircleTimer sec={resume_delay} />
                :
                <InputBase
                  className='add-to-queue__input'
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Pick Next Song"
                  inputProps={{ 'aria-label': 'search song' }}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                />
            // ) 
            
            // : <></>
            
            }
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleClick}>
              {/* <AttachMoney /> */}
              <HelpOutlineIcon />
            </IconButton>

          </Paper>
            
          <SwipeableHelpDialog open={open_tut} onClose={handleTutClose} vol_control={vol_control} anchor_el={anchor_el}/>

        </div>
        {results && <ResultsList results={results} onSelect={handleSelectElement} focus={focus} />}

        <div className='upcoming_queue'>
            
            {flat_queue && 
            <>
              <div className='upcoming_song'>
                queued songs:
              </div>
              <QueueList results={flat_queue} focus={focus} />
            </>
            }
            <div className='upcoming_song'>
              upcoming songs:
            </div>
            <DragTable uid={uid} active={true} pax_proximity={true} pax={pax} start={start} idx={idx} queued_idx={queued_idx}/>
        </div>

        {/* <Toast isopen={open_toast} />
          */}
    </div>
) 
  // if (request_sent=="sent") { 
  //     return (
  //         <div className='genre_div'>
  //             <div className='genre_container'>
  //                 <Checkbox uid={uid}/>
  //             </div>
  //             <BootstrapButton variant="contained" color="primary" className="send_prefs_button_disabled" disabled>
  //                     Your requested genre will be played shortly
  //             </BootstrapButton>
  //         </div>
  //     )

  // } else if (request_sent=="not_found") { 
  //     return (
  //         <div className='genre_div'>
  //             <div className='genre_container'>
  //                 <Checkbox uid={uid}/>
  //             </div>
  //             <BootstrapButton variant="contained" color="primary" className="send_prefs_button" onClick={sendPreferences}>
  //                 No songs left :( Request different genres
  //             </BootstrapButton>
  //         </div>
  //     ) 
  // } else { 
  // } 
}