import React, { useState, useEffect } from 'react';
import db from './firebase.config.js'
import firebase from 'firebase/app';

import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Loading from './Loading.js';
import './main.css'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        // width: '100%',
        // maxWidth: 340,
        // maxHeight: 350,
        // overflowY: 'scroll',
        // backgroundColor: 'rgba(239, 243, 246, 1)',
    },
}));

export default function CheckboxList(props) {
    const uid = props.uid
    const pax = props.pax
    const sesh_id = props.sesh_id

    const classes = useStyles();
    const [all_genres, setAllGenres] = useState(["rap", "hip hop", "pop", "r&b", "rock", "edm", "indie", "classical", "country"]);
    const pref_genres = useSelector(state => state.preferences);

    // state to monitor which genres are checked
    const [checked, setChecked] = useState([]);

    // all genres used to display/set initial genre list

    const dispatch = useDispatch();

    function sendPreferences(prefs){
        db.collection('Users').doc(uid).update({
            ['live_pax_coords.'+pax+'.pax_preferences'] : prefs,
        }, {merge: true})
    }
    async function getDriverPrefs(uid) {
        try {
            const driver_genres_ref = db.collection('Users').doc(uid)
            const id_snap = await driver_genres_ref.get();
            const driver_genres = id_snap.data().genreInterest

            setAllGenres(driver_genres)
            // setChecked(driver_genres)
            
            dispatch({type: 'Driver_Prefs', payload: {genres: driver_genres}})


        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        // setAll(({ genres }) => ({ genres: all_genres }))
        if(uid != ""){
            getDriverPrefs(uid)
            // setChecked(pref_genres)
        }

        // console.log('preferred genres', pref_genres)
        console.log('all_genres', all_genres)
        // console.log('allGenres', allGenres.genres)

        return () => {
            console.log("unmounted genres")
        }
    }, [uid])

    useEffect(() => {
        console.log('preferred genres changed to', pref_genres)
        setChecked(pref_genres)
    }, [pref_genres])
    
    const docExists = async (docName, docId) => (await db.collection(docName).doc(docId).get()).exists
    const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked)

        dispatch({type: 'Pax_Prefs', payload: {preferences: newChecked}})
        dispatch({type: 'Pax_Prefs_Changed', payload: {preferences_change: true}})
        
        console.log('from hanndle toggle referred genres changed to', newChecked)

        const pax_sesh_doc_ref = db.collection('Passengers').doc(pax).collection("sessions").doc(sesh_id)
        const pax_sesh_exists = deepDocExists("Passengers", pax, "sessions", sesh_id)
        try{
            pax_sesh_exists.then((check_val) => {
                if(check_val){
                    var unix_rn = Date.now() / 1000

                    console.log("adding interaction queue_tab_view")

                    db.collection('Passengers').doc(pax).update({
                        'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
                    })
               
                    pax_sesh_doc_ref.update({
                        'actions':firebase.firestore.FieldValue.arrayUnion('genres_requested'),
                        'interactions': firebase.firestore.FieldValue.arrayUnion({
                            'action' : 'genres_requested',
                            'meta' : newChecked,
                            'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                        }),
                    }).then((e)=>{
                        console.log("finished adding genres_requested interaction" + e)
                    })
                }else{
                    console.log("PAX_SESSIONS doesn't exist yet to add genres_requeted")
                }
            })
            
        }catch (e){
            console.log(e)
        }
        sendPreferences(newChecked);
    };


    try{
        if(all_genres && all_genres.length > 0){
            return  (
                <List className={classes.root}>
                    {all_genres.map((genre) => {
                        const labelId = `checkbox-list-label-${genre}`;
        
                        return (
                            <ListItem key={genre} dense button className="genres" onClick={handleToggle(genre)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(genre) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${genre}`} />
                                {/* <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <CommentIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>
                        );
                    })}
                </List>
            ) 
        }
        else{
            return (
                <div className='kinetic'></div>
            )
        }

    }catch(e){
        console.log(e)
        return (
            <div>
                <div className='kinetic'></div>
            </div>
        )
    }
    
    // :
    //     (
    //         <List className={classes.root}>
    //         {allGenres.genres.map((value) => {
    //             const labelId = `checkbox-list-label-${value}`;

    //             return (
    //                 <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
    //                     <ListItemIcon>
    //                         <Checkbox
    //                             edge="start"
    //                             checked={checked.indexOf(value) !== -1}
    //                             tabIndex={-1}
    //                             disableRipple
    //                             inputProps={{ 'aria-labelledby': labelId }}
    //                         />
    //                     </ListItemIcon>
    //                     <ListItemText id={labelId} primary={`${value}`} />
    //                     <ListItemSecondaryAction>
    //                         <IconButton edge="end" aria-label="comments">
    //                             <CommentIcon />
    //                         </IconButton>
    //                     </ListItemSecondaryAction>
    //                 </ListItem>
    //             );
    //         })}
    //     </List>
    //     );
}