import React, { useState, useEffect } from 'react';
import './main.css'

import spotify_logo from './spotify.svg'
import Button from '@material-ui/core/Button';
import { IconButton } from "@material-ui/core";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie';

import RemoteTabs from './Tab.js'
import TipMenu from './TipMenu.js';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Menu from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import { AttachMoney } from '@material-ui/icons';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExitToApp from "@mui/icons-material/ExitToApp";


const LoginBootstrapButton = withStyles({
    root: {
        boxShadow: 2,
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        // border: '1px solid',
        lineHeight: 1.5,
		    color: '#6C72FF',
        backgroundColor: '#FFFFFF',
        fontFamily: [
            "San Francisco", 
            'Helvetica', 
            'Arial',
            '-apple-system',
        ].join(','),
        '&:hover': {
            // backgroundColor: '#9EA2FF',
            // borderColor: '#8F94FF',
            boxShadow: 2,
        },
    },
})(Button);

const DoneBootstrapButton = withStyles({
    root: {
        boxShadow: 2,
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        // border: '1px solid',
        lineHeight: 1.5,
		    color: '#FFFFFF',
        backgroundColor: '#6C72FF',
        fontFamily: [
            "San Francisco", 
            'Helvetica', 
            'Arial',
            '-apple-system',
        ].join(','),
        '&:hover': {
            // backgroundColor: '#9EA2FF',
            // borderColor: '#8F94FF',
            boxShadow: 2,
        },
    },
})(Button);

const WhiteDoneBootstrapButton = withStyles({
  root: {
      boxShadow: 2,
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      // border: '1px solid',
      lineHeight: 1.5,
      color: '#6C72FF',
      backgroundColor: '#6C72FF',
      fontFamily: [
          "San Francisco", 
          'Helvetica', 
          'Arial',
          '-apple-system',
      ].join(','),
      '&:hover': {
          // backgroundColor: '#9EA2FF',
          // borderColor: '#8F94FF',
          boxShadow: 2,
      },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  tipButton: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    boxShadow: 2,
    textTransform: 'none',
    fontSize: 16,

    color: '#FFFFFF',
    backgroundColor: '#6C72FF',
    fontFamily: [
        "San Francisco", 
        'Helvetica', 
        'Arial',
        '-apple-system',
    ].join(','),
    '&:hover': {
        // backgroundColor: '#9EA2FF',
        // borderColor: '#8F94FF',
        boxShadow: 2,
    },

  }
}));

function Navbar(props) {
	const uid = props.uid;
	const status = props.active;
	const qr_id = props.qr_id;
	const passenger_id = props.passenger;
  const pax_sesh_id = props.pax_sesh_id;
	const name = props.driver_name;
	const pax_access_token = props.pax_access;
	const pax_refresh_token = props.pax_refresh;
	const driver_access = props.driver_access
  const driver_pax_proximity = props.driver_pax_proximity
  const pax_proximity = props.pax_proximity
  const start = props.start
  const og_idx = props.idx
  const queued_idx = props.queued_idx
  const vol_control = props.vol_control
  const queue_control = props.queue_control

  const genre_dist = props.genre_dist

  const circle_delay = props.circle_delay

  const driver_tips = useSelector(state => state.driver_tips)
  const pax_refresh_redux = useSelector(state => state.pax_refresh)

  const classes = useStyles();
  // console.log(" received uid : " + uid + " n qr_id " + qr_id + " paxid " + passenger_id + " n pax access " + pax_access_token + " driver acc " + driver_access)

  const [cookies, setCookie] = useCookies(['user']);
  
  // const passenger_id_cookie = cookies.passenger_id || ""
  // const pax_access_cookie = cookies.access_token || "NO_ACCESS"
  const pax_img_cookie = cookies.pax_img || ""
  const pax_name_cookie = cookies.pax_name || ""
  const guest_pax_name_cookie = cookies.guest_pax_name || ""

  const [paxIMG, setPaxIMG] = useState("")
  const [pax_name, setPaxName] = useState("")

  const [drawer, setDrawer] = useState(false)
  const [anchor, setAnchor] = useState('right')
  const fin_anchor = "right";

	const dispatch = useDispatch();

  const logout_pax = () => {
    setCookie('pax_name', '', { path: '/' })
    setCookie('pax_img', '', { path: '/' })
    setCookie('access_token', '', { path: '/' })
    setCookie('refresh_token', '', { path: '/' })

    dispatch({type: 'Pax_Refresh', payload: {pax_refresh: ''}})
  }
	async function auth_with_spotify(){
        const requestOptions = {
            method: 'GET',
            // headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React PUT Request Example' })
        };
        // fetch('https://remote.strive.fm/spotify_login ', requestOptions) // https://remote.strive.fm/spotify_login or http://localhost:8082/spotify_login

        const width = 450,
        height = 730,
        left = window.screen.width / 2 - width / 2,
        top = window.screen.height / 2 - height / 2;

        window.open('https://remote.strive.fm/spotify_login', 'Spotify',
        'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
          width +
          ', height=' +
          height +
          ', top=' +
          top +
          ', left=' +
          left)
        
    }

    function open_settings(){
        // setDrawer(true)
        toggleDrawer(fin_anchor, true)
        console.log('open settings')
    }

    function SpotifyLogo() {
        // console.log("LOGO ", paxIMG)
        return <img className='spotify_icon_login' src={spotify_logo} alt="LOGIN" />
    }
    function PaxLogo() {
        // console.log("LOGO ", paxIMG)
        return <img className={(paxIMG!="") ? 'auth_icon_small' : 'spotify_icon_small'} src={(paxIMG!="") ? paxIMG : spotify_logo} alt="LOGIN" />
    }
    function NavPaxLogo() {
      // console.log("LOGO ", paxIMG)
      return <img className={(paxIMG!="") ? 'auth_icon_nav' : 'spotify_icon_nav'} src={(paxIMG!="") ? paxIMG : spotify_logo} alt="LOGIN" />
  }
    
    const toggleDrawer = (anchor, open) => () => {
        // setState({ ...state, [anchor]: open });
        setDrawer(open);
    };
    
    useEffect(()=>{
      if(pax_refresh_redux == ''){
        setPaxIMG("")
        setPaxName("")
      }else{
        console.log("Logged in ? " + pax_access_token + " for name " + pax_name_cookie)
        setPaxIMG(pax_img_cookie)
        setPaxName(pax_name_cookie)
        // if(pax_img_cookie!=""){
        //   setPaxIMG(pax_img_cookie)
        // }
        // if(pax_name_cookie!=""){
        //   setPaxName(pax_name_cookie)
        // }
      }

    }, [pax_refresh_redux])

    const list = (anchor) => (
        <Box
          sx={{ width: anchor === "top" || anchor === "bottom" ? "100%" : '100%' , overflowX: 'clip'}}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >

          <Box sx={{ width: '100%', display: "flex", alignItems: "center", mb: 1, marginTop: '8px', marginLeft: '8px' }}>
            {/* Add the PaxLogo component and the "Welcome {pax_name}" message */}
            <NavPaxLogo />
            {(pax_name && pax_name != "") ? 
            <Typography variant="h6" sx={{ ml: 2 }}>
              {"Welcome " + pax_name}
            </Typography>
            :
            <Typography variant="h6" sx={{ ml: 2 }}>
              {"Welcome!"}
            </Typography>
            }
          </Box>
          <Divider />
          {"\t interact with others playing music?"}
          <br />
          {"\t auto-generate tailored playlists?"}
          <br />
          {"\t save new songs to your Library?"}
          <Divider />
          <Divider />
          {"\t Coming soon... "}
          <Divider />
          {/* {"\t Coming soon... (generate playlists? insta-save songs?)"}
          <Divider />
          {"\t Genre Settings"}
          <Divider /> */}
          {/* <List>
            {["Add your friends", ""].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {"\tQueue Control Settings"}
          <Divider />
          {"\tMusic Playback Settings"}
          <Divider />
          <List>
            {["Volume Control", "Auto Skip ?", "Help"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
           */}
          <Divider />
          <List>
            {["Log Out"].map((text, index) => (
              <ListItem className='logout_button' key={text} disablePadding>
                <ListItemButton onClick={logout_pax}>
                  <ListItemIcon className='logout_icon'>
                    {index % 2 === 0 ? <ExitToApp /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Typography
              variant="body2"
              sx={{ textAlign: "center", mt: 2, mb: 1, color: "text.secondary", position: "absolute", bottom: 0, marginLeft: '8px', marginBottom: '8px' }}
            >
            {"\u00A9"} 2023 Strive.FM <span style={{ color: 'purple' }}>&#x2764;&#xfe0f;</span>
          </Typography>
        </Box>
      );

	return (
			<div className='remote_bar'>
				{/* <h1 className='intro'>Strive Remote</h1> */}
        <div className='nav_top'>
          <DoneBootstrapButton variant="contained" color="primary" className={pax_refresh_redux == '' ? "profile_button_connect" : "profile_button"} onClick={pax_refresh_redux == '' ? auth_with_spotify : toggleDrawer(fin_anchor, true)}>
              <PaxLogo /> 
              <div className='pax_names'>
                {(pax_name !== "" ? pax_name : (guest_pax_name_cookie !== "" ? guest_pax_name_cookie : "fan_0"))}
              </div>
          </DoneBootstrapButton>
          
          {/* <IconButton classes={{ label: classes.tipButton }} className="tip_button" endIcon={<KeyboardArrowUpIcon />}>
                  <AttachMoney />
                  <div>Tip</div>
                  < TipMenu />
          </IconButton> */}
          {/* < TipMenu driver_tips={driver_tips}/> */}
          {/* <div className= {true ? "nav_tabs" : "tabs"}> */}
          
          <RemoteTabs qr_id={qr_id} uid={uid} active={status} passenger={passenger_id} driver_pax_proximity={driver_pax_proximity} pax_proximity={pax_proximity} pax_access={pax_access_token} driver_access={driver_access} start={start} idx={og_idx} queued_idx={queued_idx} queue_control={queue_control} vol_control={vol_control} genre_dist={genre_dist} circle_delay={circle_delay}/>
          
          {/* </div> */}
          
          < TipMenu uid={uid} driver_tips={driver_tips} pax={passenger_id} pax_sesh_id={pax_sesh_id}/>

        {
          pax_refresh_redux == '' ?  
          <DoneBootstrapButton variant="contained" color="primary" className="login_request_button" onClick={auth_with_spotify}>
              Login <SpotifyLogo />
          </DoneBootstrapButton>
          :
          <>
              <DoneBootstrapButton variant="contained" color="primary" className="hamburger" onClick={toggleDrawer(fin_anchor, true)}>
                <Menu />
              </DoneBootstrapButton>
              <div key={fin_anchor}>
                  {/* <Button onClick={toggleDrawer(fin_anchor, true)}>{fin_anchor}</Button> */}
                  <SwipeableDrawer
                      anchor={fin_anchor}
                      open={drawer}
                      onClose={toggleDrawer(fin_anchor, false)}
                      onOpen={toggleDrawer(fin_anchor, true)}
                  >
                  {list(fin_anchor)}
                  </SwipeableDrawer>
              </div>
            </>
        }
          {/* <div className={(pax_refresh_token == "NO_REFRESH") ? 'nav_spacer_connect' : 'nav_spacer'}></div> */}
          
        </div>
        {/* <div className= {true ? "nav_tabs" : "tabs"}>
          <RemoteTabs qr_id={qr_id} uid={uid} active={status} passenger={passenger_id} driver_pax_proximity={driver_pax_proximity} pax_proximity={pax_proximity} pax_access={pax_access_token} driver_access={driver_access} start={start} idx={og_idx}/>
        </div> */}


			</div>
	)
}

export default Navbar;