import React, { useState, useEffect } from 'react';
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tune from "@mui/icons-material/Tune";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VolumeUp from "@mui/icons-material/VolumeUp";
import VolumeOff from "@mui/icons-material/VolumeOff";
import SkipNext from "@mui/icons-material/SkipNext";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Slider from "@mui/material/Slider";
import { IconButton } from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';

import db from './firebase.config.js';
import firebase from 'firebase/app';
import "firebase/firestore";

import venmo_logo from './Venmo_Logo_Blue.png'
import paypal_logo from './pp_logo.svg'
import cashapp_logo from './Cash_app_logo.svg'


function valuetext(value) {
  return `${value}°C`;
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export default function TipMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("list");
  const [vol, setVol] = useState(1);
  const open = Boolean(anchorEl);

  const driver_tips = props.driver_tips
  // const venmo_u = "Yash-Bolisetty"
  const uid = props.uid
  const pax=props.pax
  const pax_sesh_id = props.pax_sesh_id

  
  const deepDocExists = async (docName, docId, docName2, docId2) => (await db.collection(docName).doc(docId).collection(docName2).doc(docId2).get()).exists

  const handleClick = (event) => {
    const curr_os = get_os()
    if (uid && pax_sesh_id && pax && driver_tips && driver_tips != {} && Object.keys(driver_tips).length > 0) {
      console.log("pax" + pax + " and sesh id " + pax_sesh_id + "tips setup n clicked from " + (curr_os === 'ios' ? 'guessed ios' : 'whoops, was: '+curr_os))

      const check_pax_sesh = deepDocExists("Passengers", pax, "sessions", pax_sesh_id)
      try{ 
        check_pax_sesh.then((check_val)=> {
            if(check_val){
                const unix_rn = Date.now() / 1000
                console.log("adding tip clicked interaction: ", pax_sesh_id)
                db.collection('Passengers').doc(pax).update({
                  'last_used' : firebase.firestore.FieldValue.serverTimestamp(),
                })

                db.collection('Passengers').doc(pax).collection("sessions").doc(pax_sesh_id).update({
                    'actions':firebase.firestore.FieldValue.arrayUnion('tips_clicked'),
                    'interactions': firebase.firestore.FieldValue.arrayUnion({
                        'action' : 'tips_clicked',
                        'time' : unix_rn //firebase.firestore.FieldValue.serverTimestamp(),
                    }),
                }).then((e)=>{
                    console.log("finished updating same pax doc ", e)
                })
            }
        })

        db.collection('Users').doc(uid).update({
          'tip_clicks' : firebase.firestore.FieldValue.increment(1)
        })
      }catch (e) {
        console.log("failed adding queue interaction ")
      }
    }else{
      alert("Notify driver to setup Tips in Strive App")

    }

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

  };

  const get_deeplinks = (venmo_u, cash_u, paypal_u) => {

    const tip_deeplinks = {
      'venmo' : {
        'ios' : 'venmo://paycharge?txn=pay&recipients='+venmo_u,
        'android': 'intent://paycharge?txn=pay&recipients=' + venmo_u + '#Intent;package=com.venmo;scheme=venmo;end',
        'default' : 'https://account.venmo.com/u/'+venmo_u
      },
      'cashapp' : {
        'ios' : 'cashme://cash.me/$'+cash_u,
        'android': 'intent://cash.me/$' + cash_u +'#Intent;package=com.squareup.cash;scheme=https;end',
        'default' :  "https://cash.app/$"+cash_u
      },
      'paypal' : {
        'ios' : 'cashme://cash.me/$'+paypal_u,
        'android': 'intent://cash.me/$' + paypal_u +'#Intent;package=com.squareup.cash;scheme=https;end',
        'default' :  "https://cash.app/$"+paypal_u
      }
    }

    return tip_deeplinks
  }
  const get_os = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "android";
    }

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "unknown";

  }
  const handlePaypal = () => {
    // navigator.clipboard.writeText(driver_tips['paypal'])
    // alert("Paypal handle " + driver_tips['paypal'] + " copied to clipboard so please send tip in Paypal app!")
    navigator.clipboard.writeText(driver_tips['paypal']).then(() => {
      alert("Paypal handle @" + driver_tips['paypal'] + " copied to clipboard, please send tip in Paypal app!")

    })

  }
  const handleVenmo = (payment_app) => {
    const curr_os = get_os()
    console.log("outside handling " + payment_app + " with driver tips " + driver_tips)

    if (driver_tips && driver_tips != {} && Object.keys(driver_tips).length > 0) {
      console.log("handling " + payment_app)

      const deeplinks = get_deeplinks(driver_tips["venmo"],driver_tips["cashapp"],driver_tips["paypal"],)
      const venmo_link = (curr_os === "ios" ? deeplinks[payment_app]["ios"] : deeplinks[payment_app]["android"])
      const default_venmo_link = deeplinks[payment_app]["default"]
      // console.log("Venmo clicked from " + (Platform.OS === 'ios' ? 'ios' : 'android'))

      setAnchorEl(null);
      try {
        window.open(venmo_link, (bdd)=>{
          console.log("in here", bdd)
        })
      } catch (error) {
        console.log("erred handling venmo deeplink "+error)
        setTimeout(function() {
          window.location = default_venmo_link;
          //window.open(default_venmo_link);

        }, 500);
      }

      // if venmo app not installed, also open new window
      setTimeout(function() {
        window.open(default_venmo_link);
      }, 5000);
    

    }
    // else{
    //   alert("tell driver to finish setting up tips")
    // }
    
  };

  function VenmoLogo() {
    const curr_os = get_os()
    // const venmo_link = (curr_os === "ios" ? tip_deeplinks["venmo"]["ios"] : tip_deeplinks["venmo"]["android"])
    return <img className='venmo_logo' src={venmo_logo} alt="Venmo" />
  }
  function PayPal() {
    return <img className='paypal_logo' src={paypal_logo} alt="PayPal" />
  }
  function CashApp() {
    return <img className='cashapp_logo' src={cashapp_logo} alt="CashApp" />
  }

  // useEffect(()=>{
    
  // }, [driver_tips])
  return (
    <div className='tip_div'>
        <Button
          id="tip-menu-button"
          className='tip-menu-button'
          aria-controls={open ? "popup-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<ExpandMore />}
        >
          <AttachMoney />
          Tip
        </Button>

        {/* <IconButton 
          classes={{ label: classes.tipButton }} 
          className="tip_button" 
          endIcon={<KeyboardArrowUpIcon />}
        >
          <AttachMoney />
          <div>Tip</div>
        </IconButton> */}

        <StyledMenu
          id="popup-menu"
          MenuListProps={{
            "aria-labelledby": "tip-menu-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 54, left: 125 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          {/* <MenuItem disableRipple endIcon={<ExpandMore />}>
            Tip Driver via
            
          </MenuItem> */}
          {
          (Object.keys(driver_tips).length > 0 && (
            ("venmo" in driver_tips && driver_tips["venmo"]!=="") ||
            ("paypal" in driver_tips && driver_tips["paypal"]!=="") ||
            ("cashapp" in driver_tips && driver_tips["cashapp"]!==""))) ?
          <>
            {/* <Divider sx={{ my: 0.5 }} /> */}

            {
              (driver_tips['venmo'] !== "") ?
                <>
                <MenuItem onClick={() => {handleVenmo("venmo")}} disableRipple>
                <div className='tip_line'>
                  <div className='tip_text'>
                    <VenmoLogo />
                    {"Venmo"}
                  </div>
                  <div className='tip_handle'>
                    {"@" + driver_tips['venmo']}
                  </div>
                  </div>
                  {/* send via Venmo */}
                </MenuItem>

                <Divider sx={{ my: 0.5 }} />
                </> : <></>

            }

            {
              (driver_tips['paypal'] !== "") ?
              <>
                <MenuItem onClick={() => {handlePaypal()}} disableRipple>
                  <div className='tip_line'>
                  <div className='tip_text'>
                    <PayPal />
                    {"Paypal"}
                  </div>
                  <div className='tip_handle'>
                    {"@" + driver_tips['paypal']}
                  </div>
                  </div>
                  {/* send via PayPal */}
                </MenuItem>
                          
                <Divider sx={{ my: 0.5 }} />
              </>
              : 
              <></>
            }


            {
              (driver_tips['cashapp'] !== "") ?
              <>
                <MenuItem onClick={() => {handleVenmo("cashapp")}} disableRipple>
                  <div className='tip_line'>
                    <div className='tip_text'>
                      <CashApp />
                      {"CashApp"}
                    </div>
                    <div className='tip_handle'>
                    {"@" + driver_tips['cashapp']}
                    </div>
                  </div>
                  {/* send via PayPal */}
                </MenuItem>
                          
                <Divider sx={{ my: 0.5 }} />
              {/* send via PayPal */}
              </>
              : 
              <></>
            }
            {/* <Divider sx={{ my: 0.5 }} /> */}

            {/* <MenuItem onClick={handleClose} disableRipple>
              <MoreHorizIcon />
              Full Settings
            </MenuItem> */}
          </>
          : 
          <>
            <MenuItem disableRipple endIcon={<ExpandMore />}>
              Tell Driver to Setup Tips (send via Strive Apple | Google Pay)
            </MenuItem>
          </>
          }
        </StyledMenu>
    </div>
  );
}
