import React, { useState, useEffect } from 'react';
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tune from "@mui/icons-material/Tune";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VolumeUp from "@mui/icons-material/VolumeUp";
import VolumeOff from "@mui/icons-material/VolumeOff";
import SkipNext from "@mui/icons-material/SkipNext";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Slider from "@mui/material/Slider";

import db from './firebase.config.js';

function valuetext(value) {
  return `${value}°C`;
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export default function PopupMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState("list");
  const [vol, setVol] = useState(1);
  const open = Boolean(anchorEl);
  const vol_control = props.vol_control

  const uid = props.uid
  const marks = [
    {
      value: 35,
      label: '35s',
    },
    {
      value: 60,
      label: '1m',
    },
    {
      value: 120,
      label: '2m',
    },
    {
      value: 180,
      label: '3m',
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (
    event,
    nextView
  ) => {
    setView(nextView);
  };

  const handleVolumeOn = (
    event,
    nextView
  ) => {
    setVol(1);
    db.collection('Users').doc(uid).update({
      'pax_volume': 0.6
    }, {merge: true})
  };

  const handleVolumeOff = (
    event,
    nextView
  ) => {
    setVol(0);
    db.collection('Users').doc(uid).update({
      'pax_volume': 0
    }, {merge: true})

  };

  const autoskip_change = (event, new_time) => {
    console.log('auto skipped to ', new_time)
    db.collection('Users').doc(uid).update({
      'config.autoSkipAt': new_time
    }, {merge: true})
  }
  return (
    <div>
      
      <div>
        <Button
          id="menu-button"
          className='menu-button'
          aria-controls={open ? "popup-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowUpIcon />}
        >
          <Tune />
        </Button>
        <StyledMenu
          id="popup-menu"
          MenuListProps={{
            "aria-labelledby": "menu-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 420, left: 185 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <MenuItem disableRipple>
            {/* <SkipNext /> */}
            Set Auto-Skip (sec.)
          </MenuItem>
          <MenuItem disableRipple>
            <SkipNext />

            <Slider
              aria-label="Auto-Skip"
              onChange={autoskip_change}
              defaultValue={35}
              getAriaValueText={valuetext}
              step={15}
              marks={marks}
              min={35}
              max={180}
              valueLabelDisplay="on"
            />
          </MenuItem>

          <Divider sx={{ my: 0.5 }} />
          
          {vol_control ? 
          <MenuItem disableRipple>

            <ToggleButtonGroup
              orientation="horizontal"
              value={view}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton
                value="list"
                aria-label="list"
                onClick={handleVolumeOff}
              >
                <VolumeOff />
              </ToggleButton>

              <ToggleButton
                value="module"
                aria-label="module"
                onClick={handleVolumeOn}
              >
                <VolumeUp />
              </ToggleButton>
            </ToggleButtonGroup>
            Volume
          </MenuItem>
          :
          <></>
          }
          <Divider sx={{ my: 0.5 }} />

          <MenuItem onClick={handleClose} disableRipple>
            <MoreHorizIcon />
            Full Settings
          </MenuItem>
        </StyledMenu>
      </div>
    </div>
  );
}
