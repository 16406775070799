const initialState = {
    count: 0,
    song: "", 
    last_queued_song: "", 
    cdn: "",
    artist: "",
    url: "",
    genres: ["rap", "hip hop", "pop", "r&b", "rock", "edm", "indie", "classical", "country"],
    preferences: ["rap", "hip hop", "pop", "r&b", "rock", "edm", "indie", "classical", "country"],
    preferences_change: false,
    location_granted: false,
    geoLocation: [],
    coords: [],
    error: "NONE",
    session_id: "",
    access_token: "",
    pax_queue: {},
    driver_tips: {},
    pax_refresh: '',

}

export function rootReducer(state = initialState, action){
    switch (action.type) {
        case 'Increment':
            return {...state, count: state.count + 1};

        case 'Decrement':
            return {...state, count: state.count - 1};

        case 'Song_Update': 
            return { 
                ...state,
                song: action.payload.song,
            };
        case 'Queued_Song_Update': 
            return { 
                ...state,
                last_queued_song: action.payload.last_queued_song,
            };
        case 'CDN_update': 
            return { 
                ...state,
                cdn: action.payload.cdn,
            };
        case 'Artist_update': 
            return { 
                ...state,
                artist: action.payload.artist,
            };
        case 'URL_update': 
            return { 
                ...state,
                url: action.payload.url,
            };
        case 'Pax_Prefs':
            return {
                ...state,
                preferences: action.payload.preferences,
            }
        case 'Pax_Prefs_Changed':
            return {
                ...state,
                preferences_change: action.payload.preferences_change,
            }
        case 'Pax_Refresh':
                return {
                    ...state,
                    pax_refresh: action.payload.pax_refresh,
                }
        case 'Driver_Prefs':
                return {
                    ...state,
                    genres: action.payload.genres,
                    // preferences: action.payload.genres
            }
        case 'Pax_Queue':
            return {
                ...state,
                pax_queue: action.payload.pax_queue,
        }
        case 'Location_Request_Granted':
            return {
                ...state,
                location_granted: true,
            }
        case 'Location_Request_Declined':
            return {
                ...state,
                location_granted: false,
            }
        case 'Pax_Coords_Update': 
            return {
                ...state,
                coords : action.payload.coords,
            }
        case 'Location_Error_Denied':
                return {
                    ...state,
                    error: "LOCATION_DENIED",
                }
        case 'Location_Error_Reset':
                return {
                    ...state,
                    error: "NONE",
                }
        case 'session_id_update':
                return {
                    ...state,
                    session_id: action.payload.session_id,
                }  
        case 'access_token_update':
                return {
                    ...state,
                    access_token: action.payload.access_token,
                }    
        case 'Driver_Tips_Update':
                    return {
                        ...state,
                        driver_tips: action.payload.driver_tips,
                }    
        default:
            return state;
    }
}