import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import { CookiesProvider } from "react-cookie";
import {createStore} from 'redux'
import {rootReducer} from './reducers/rootReducer.js'

const store = createStore(rootReducer)

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <App className='app' style={{ justifyContent: 'center' }} />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
// ReactDOM.hydrate(
//   <React.StrictMode>
//     <App className = 'app' uid = '9nEntCGkoDMujg4m8gRxU8okUEH3' style={{justifyContent: 'center'}}/>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
